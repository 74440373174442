import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgxTimerModule } from 'ngx-timer';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select'; 
import { ɵs } from '@ng-select/ng-select';

import { ExportarExcelComponent } from './exportar-excel/exportar-excel.component';

// import { DataTablesModule } from "angular-datatables";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


//  cSpell:disable 


@NgModule({
  declarations: [
    AppComponent,
    ExportarExcelComponent
  ],
  imports: [
    BrowserModule,    
    NgbModule,
    HttpClientModule,
    AppRoutingModule,
    NgSelectModule,    
    NgxTimerModule,    
    FormsModule,    
    ReactiveFormsModule
    
  ],
  providers: [NgSelectConfig, ɵs],
  bootstrap: [AppComponent]
})
export class AppModule { }
