// cSpell:disable

import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { map, delay, filter, scan, catchError, finalize, take, first, publishReplay, share } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscriber, Subject } from 'rxjs';

import * as PouchDB from 'node_modules/pouchdb/dist/pouchdb';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { async } from '@angular/core/testing';


@Injectable({
  providedIn: 'root'
})
export class Knowbe4ServiceService {

  // Data

  public dataCuenta : any[];
  
  public dataUsuariosSrv : any[] = [];
  
  public dataGerenciasSrv : any[] = [];
  public dataDepartamentosSrv : any[] = [];
  public dataCargosSrv : any[] = [];

  public dataGruposSrv : any[] = [];

  public dataPishingSrv : any[] = [];        // Todas las campanas
  public dataPishingStaSrv : any[] = [];        // Todas las campanas

  public dataPishingAllPstSrv : any[] = [];
  public dataAllPstXcamp : any[] = [];
  public dataAllResultados : any[] = [];
  
  public dataTrainingSrv : any[] = [];
  public dataTrainingStaSrv : any[] = [];

  public dataAlltrainingUsers : any[] = [];
  public dataAlltrainingStaUsers : any[] = [];

  public dataPurchasesSrv : any[] = [];

  public estaConectado : boolean = false;

  public totalRegistrosDescargados = new Subject<Number>();
  public totalRegistrosCache = new Subject<Number>();
  public totalRegPreFiltrados = new Subject<Number>();  
  public totalRegFiltrados = new Subject<Number>();  

  public contRegistrosDescargados : number = 0;
  public contRegistrosCache : number = 0;
  public contRegPreFiltrados : number = 0;  
  public contRegFiltrados : number = 0;  
  
  public totalSubProcesados= new Subject<Number>();
  public cantiSubProcesados= new Subject<Number>();

  public porcenGeneral = new Subject<Number>();
  public porcenSubProc = new Subject<Number>();

  public procesoEnCurso= new Subject<string>();
  public bitacoraLog = new Subject<string>();

  public srvGerencias = new Subject<string[]>();
  public srvDepartamentos = new Subject<string[]>();
  public srvCargos = new Subject<string[]>();
  public srvUsuarios = new Subject<any[]>();
  public srvGrupos = new Subject<any[]>();

  public srvTraining = new Subject<any[]>();
  public srvTraStates = new Subject<any[]>();
  public srvTrainingUsers = new Subject<any[]>();
  public srvPurchases = new Subject<any[]>();

  public srvPishing = new Subject<any[]>();
  public srvPisStates = new Subject<any[]>();
  public srvPishingSrvAllPst = new Subject<any[]>();

  public srvAllPstXcamp = new Subject<any[]>();
  public srvAllResultados = new Subject<any[]>();
  public srvAllResultadosSta = new Subject<any[]>();

  public swConectado = new Subject<boolean>();

  public swCargando = new Subject<boolean>();
  public swCargado = new Subject<boolean>();

  public swPrefiltrado = new Subject<boolean>();
  public swPrefiltrando = new Subject<boolean>();

  public swFiltrando = new Subject<boolean>();
  public swFiltrado = new Subject<boolean>();
  
  public swCancelado = new Subject<boolean>();

  public Conectado = false;
  
  public Cargando = false;
  public Cargado = false;
  
  public Prefiltrado = false;
  public Prefiltrando = false;

  public Filtrando = false;
  public Filtrado = false;

  public cancelado = false;
  

  public swTimer = new Subject<string>();

  // Cache

  BD: any[] = [];

  cacheDB_Conf : any;
  cacheDB_Site : any;
  cacheDB_User : any;
  cacheDB_Grup : any;
  cacheDB_Pish : any;
  cacheDB_PishAllPst : any;
  cacheDB_PishAllPstxCamp : any;
  cacheDB_AllResultados : any;
  cacheDB_Trai : any;
  cacheDB_Modu : any;
  cacheDB_TraiUsers : any;

  // Configuracion

  public url = 'https://us.api.knowbe4.com/v1/';
  // public token = 'eyJhbGciOiJIUzI1NiJ9.eyJhaWQiOjQ0NjcyNywiaWF0IjoxNjAxNTE0NTgxfQ.XQpS2DlWVVNzZ0VQgYTUBPnXFxpcQem1Rr9H50_zE9c';
  public token = '';

  public defaultUrl = 'https://us.api.knowbe4.com/v1/';
  // public defaultToken = 'eyJhbGciOiJIUzI1NiJ9.eyJhaWQiOjQ0NjcyNywiaWF0IjoxNjAxNTE0NTgxfQ.XQpS2DlWVVNzZ0VQgYTUBPnXFxpcQem1Rr9H50_zE9c';
  public defaultToken = 'eyJhbGciOiJIUzI1NiJ9.eyJhaWQiOjQ0NjcyNywiaWF0IjoxNjAxNTE0NTgxfQ.XQpS2DlWVVNzZ0VQgYTUBPnXFxpcQem1Rr9H50_zE9c';

  public cliente : string = '';
  public dominio: string = '';

  public registros_status = 'active';
  public page=1;
  public per_page=500;
  public seccion=7200;
  public cntApi = 0;

  // Filtros globales

  // Filtros bloque

  dataDesde : NgbDate ;
  dataHasta : NgbDate ;
  httpOptions = {};

  constructor( private http: HttpClient,
               private calendar: NgbCalendar ) { 
                 
    this.dataDesde = calendar.getPrev( calendar.getToday(), 'm', 36);
    this.dataHasta = calendar.getNext( calendar.getToday(), 'd', 1);

    this.swCancelado.subscribe( status => {
      this.cancelado = status;      
    })   

    this.cacheDB_Conf = new PouchDB("Conf");
    this.cacheDB_Site = new PouchDB("Site");
    this.cacheDB_User = new PouchDB("Usuarios");
    this.cacheDB_Grup = new PouchDB("Grupos");    
    this.cacheDB_Trai = new PouchDB("Training");
    this.cacheDB_Pish = new PouchDB("Piching");
    this.cacheDB_Modu = new PouchDB("Modulos");
    this.cacheDB_PishAllPst = new PouchDB("Piching-PST");
    this.cacheDB_PishAllPstxCamp = new PouchDB("Piching-PST-xcamp");
    this.cacheDB_AllResultados = new PouchDB("Piching-All-Resultados");
    this.cacheDB_TraiUsers = new PouchDB("Training-xUser");  

    this.loadInfoCache();

  }

  public async usuariosKnowbe4( page, reg ):Promise<any>  { 
    
    this.cntApi ++; 

    const ruta = `users?status=${this.registros_status}&page=${page}&per_page=${reg}`;     
    return await this.http.get(this.url+ruta, this.httpOptions).pipe( delay(850)).toPromise();    
    
  }

  public async gruposKnowbe4(page, reg):Promise<any>  {
    
    this.cntApi ++;

    const ruta = `groups?status=${this.registros_status}&page=${page}&per_page=${reg}`;        
    return await this.http.get(this.url+ruta, this.httpOptions).pipe( delay(850)).toPromise();    
    
  }

  public async trainingKnowbe4(page,reg):Promise<any>  {    

    this.cntApi ++;

    const ruta = `training/campaigns?page=${page}&per_page=${reg}`;        
    return await this.http.get(this.url+ruta, this.httpOptions).pipe( delay(850)).toPromise();    
    
  }

  public async pishingKnowbe4(page,reg):Promise<any>  {
    
    this.cntApi ++;

    const ruta = `phishing/campaigns?page=${page}&per_page=${reg}`;       
    return await this.http.get(this.url+ruta, this.httpOptions).pipe( delay(850)).toPromise();
  
  }

  public async pishingAllPstKnowbe4(page,reg):Promise<any>  {    

    this.cntApi ++;

    const ruta = `phishing/security_tests?page=${page}&per_page=${reg}`;        
    return await this.http.get(this.url+ruta, this.httpOptions).pipe( delay(850)).toPromise();    
    
  }

  public async pishingAllPstXcampKnowbe4(id,page,reg):Promise<any>  {    

    this.cntApi ++;
    
    const ruta = `phishing/campaigns/${id}/security_tests?page=${page}&per_page=${reg}`;    
    return await this.http.get(this.url+ruta, this.httpOptions).pipe( delay(850)).toPromise();    
    
  }

  public async pishingAllResultadosKnowbe4(id, page, reg):Promise<any>  {    

    this.cntApi ++;

    const ruta = `phishing/security_tests/${id}/recipients?page=${page}&per_page=${reg}`;            
    return await this.http.get(this.url+ruta, this.httpOptions).pipe( delay(850)).toPromise();    
    
  }

  public async trainingUsersKnowbe4(id,page,reg):Promise<any>  {    

    this.cntApi ++;

    const ruta = `training/enrollments?campaign_id=${id}&page=${page}&per_page=${reg}`;        
    return await this.http.get(this.url+ruta, this.httpOptions).pipe( delay(850)).toPromise();    
    
  }

  public async purchasestrainingKnowbe4(page,reg):Promise<any>  {    

    this.cntApi ++;

    const ruta = `training/store_purchases?page=${page}&per_page=${reg}`;    
    return await this.http.get(this.url+ruta, this.httpOptions).toPromise();    
    
  }


  public async cargarUsuarios() {

    let continuar = true;                 
    let page =1;

    this.dataUsuariosSrv = [];
    
    while (continuar ) {
        
      await this.usuariosKnowbe4( page, this.per_page ).then( loteReg => {
        

        const reg:any[] = loteReg;        
        
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
        
        if (reg.length>0) {

          reg.forEach( ( item,i) => {            

            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);

            this.bitacoraLog.next( 'Procesando : '+item.first_name+' '+item.last_name );
            this.dataUsuariosSrv.push(item);
          })
          page = page +1;
          continuar =true;
        } else {
          continuar = false;
          this.procesoEnCurso.next("Finalizada la descarga de usuarios, un total de "+this.dataUsuariosSrv.length.toString());
          this.bitacoraLog.next( ' ' );
          this.srvUsuarios.next( this.dataUsuariosSrv );
          
        }       
        
      });
      
    }

  }

  public async cargarUsuariosCache() : Promise< any > {

    return new Promise( async resolve => { 

      this.dataUsuariosSrv = [];      

      await this.cacheDB_User.allDocs( { include_docs: true, attachments: true }).then( docs =>{ 

        const reg:any[] = docs.rows;        
          
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
          
        if (reg.length>0) {

          reg.forEach( ( item,i) => {            

            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);

            this.bitacoraLog.next( 'Procesando : '+item.first_name+' '+item.last_name );
            this.dataUsuariosSrv.push(item.doc.data);

          })
          
          this.procesoEnCurso.next("Finalizada la carga usuarios del cache, un total de "+this.dataUsuariosSrv.length.toString());
          this.bitacoraLog.next( ' ' );
            
          this.srvUsuarios.next( this.dataUsuariosSrv );

          resolve({ok:true});
        }

      });

    });

  }
  

  public async cargarGrupos() {

    let continuar = true;                 
    let page = 1;

    this.dataGruposSrv = [];
    
    while (continuar) {
        
      await this.gruposKnowbe4( page, this.per_page ).then( loteReg => {

        const reg:any[] = loteReg;        
        
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
        
        if (reg.length>0) {

          reg.forEach( item => {

            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);
            
            this.bitacoraLog.next( 'Procesando : '+item.name );
            this.dataGruposSrv.push(item);
          })
          page = page +1;
          continuar =true;
        } else {
          continuar = false;
          this.procesoEnCurso.next("Finalizada la descarga de grupos, un total de "+this.dataGruposSrv.length.toString());
          this.bitacoraLog.next( ' ' );
          this.srvGrupos.next( this.dataGruposSrv );
          
        }       
        
      })
      
    }    
    
  }

  public async cargarGruposCache(): Promise<any> {    

    return new Promise( async resolve =>{ 

      this.dataGruposSrv = [];      
      
      await this.cacheDB_Grup.allDocs( { include_docs: true, attachments: true }).then( docs =>{ 
  
          const reg:any[] = docs.rows;        
          
          this.contRegistrosDescargados += reg.length ;
          this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
          
          reg.forEach( item => {
  
            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);
              
            this.bitacoraLog.next( 'Procesando : '+item.name );
            this.dataGruposSrv.push(item.doc.data);
  
          })
            
          this.procesoEnCurso.next("Finalizada la carga de grupos del cache, un total de "+this.dataGruposSrv.length.toString());
          this.bitacoraLog.next( ' ' );
          this.srvGrupos.next( this.dataGruposSrv ); 
          resolve({ok:true});
          
      })  

    } )
   
  }

  

  public async cargarPishing() {

    let continuar = true;                 
    let page = 1;

    this.dataPishingSrv = [];
    this.dataPishingStaSrv = [];
    
    while (continuar) {
        
      await this.pishingKnowbe4(page, this.per_page ).then( loteReg => {

        const reg:any[] = loteReg;        
        
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
        
        if (reg.length>0) {

          reg.forEach( item => {

            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);            

            this.bitacoraLog.next( 'Procesando : '+item.name );
            this.dataPishingSrv.push(item);
          })
          page = page +1;
          continuar =true;
        } else {
          continuar = false;
          this.procesoEnCurso.next("Finalizada la descarga de pishing, un total de "+this.dataPishingSrv.length.toString());
          this.bitacoraLog.next( ' ' );
          this.dataPishingSrv.forEach( (p,i) =>{

            if ( this.dataPishingStaSrv.indexOf( String(p.status).toUpperCase() )< 0 ) {
              this.dataPishingStaSrv.push(String(p.status).toUpperCase())
            }
      
          })
          this.srvPishing.next( this.dataPishingSrv );
          
        }       
        
      })
      
    } 

  }

  public async cargarPishingCache() : Promise <any> {

    return new Promise( async resolve =>{ 

      this.dataPishingSrv = [];
      this.dataPishingStaSrv = [];

      await this.cacheDB_Pish.allDocs( { include_docs: true, attachments: true }).then( docs =>{ 
  
        const reg:any[] = docs.rows;        
        
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
        
        if (reg.length>0) {
  
          reg.forEach( item => {
  
            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);            
  
            this.bitacoraLog.next( 'Procesando : '+item.doc.data.name );
            this.dataPishingSrv.push(item.doc.data);
          })         
    
          this.procesoEnCurso.next("Finalizada la carga de pishing del cache, un total de "+this.dataPishingSrv.length.toString());
          this.bitacoraLog.next( ' ' );
  
          this.dataPishingSrv.forEach( (p,i) =>{
  
            if ( this.dataPishingStaSrv.indexOf( String(p.status).toUpperCase() )< 0 ) {
              this.dataPishingStaSrv.push(String(p.status).toUpperCase())
            }
      
          })


          
          this.srvPishing.next( this.dataPishingSrv );
          
          
        } 
           
        resolve({ok:true});
        
      })

    })
    
    

  }

  

  public async cargarAllPstPishing() {

    let continuar = true;                 
    let page = 1;

    this.dataPishingAllPstSrv = [];
    
    while (continuar) {
        
      await this.pishingAllPstKnowbe4(page, this.per_page).then( loteReg => {

        const reg:any[] = loteReg;        
        
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
        
        if (reg.length>0) {

          reg.forEach( item => {

            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);            

            this.bitacoraLog.next( 'Procesando : '+item.name );
            this.dataPishingAllPstSrv.push(item);
          })
          page = page +1;
          continuar =true;
        } else {
          continuar = false;
          this.procesoEnCurso.next("Finalizada la descarga de todos los pishing security tests (PST), un total de "+this.dataPishingAllPstSrv.length.toString());
          this.bitacoraLog.next( ' ' );
          
          
        }       
        
      })
      
    }    

    if ( this.dataPishingAllPstSrv.length > 0 )   this.srvPishingSrvAllPst.next( this.dataPishingAllPstSrv );
    
  }

  public async cargarAllPstPishingCache() : Promise <any> {

    return new Promise( async resolve =>{      

      this.dataPishingAllPstSrv = [];      
      
      await this.cacheDB_PishAllPst.allDocs( { include_docs: true, attachments: true }).then( docs =>{

        const reg:any[] = docs.rows;        
        
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
        
        if (reg.length>0) {

          reg.forEach( item => {

            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);            

            this.bitacoraLog.next( 'Procesando : '+item.name );
            this.dataPishingAllPstSrv.push(item.doc.data);

          })            
        
          this.procesoEnCurso.next("Finalizada la carga de cache de los pishing security tests (PST), un total de "+this.dataPishingAllPstSrv.length.toString());
          this.bitacoraLog.next( ' ' );

          // console.llog('Los datos',this.dataPishingAllPstSrv);

          this.srvPishingSrvAllPst.next( this.dataPishingAllPstSrv ); 
          
        }       

        resolve({ok:true});
          
      });
    });
  }
  

  public async cargarAllPstXcamp() {
// TODO : por corregir
    
    this.dataAllPstXcamp = [];
    let page = 1;
    
    this.dataPishingAllPstSrv.forEach( async (Pish, inx) => {
      
      let continuar = true;                 
      page =1;

      while (continuar) {
          
        await this.pishingAllPstXcampKnowbe4( Pish.campaign_id, page, this.per_page ).then( loteReg => {          
  
          const reg:any[] = loteReg;        
          
          this.contRegistrosDescargados += reg.length ;
          this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
          
          if (reg.length>0) {
  
            reg.forEach( item => {
  
              this.contRegPreFiltrados += 1 ;
              this.totalRegPreFiltrados.next(this.contRegPreFiltrados);            
  
              this.bitacoraLog.next( 'Procesando : '+item.name );
              this.dataAllPstXcamp.push(item);

            })

            page = page +1;
            continuar =true;

          } else {

            continuar = false;
            this.procesoEnCurso.next("Finalizada la descarga de todos los PST por campaña, un total de "+this.dataAllPstXcamp.length.toString());
            this.bitacoraLog.next( ' ' );
            
          }       
          
        })
        
      }    
      
    } )
    
    this.srvAllPstXcamp.next( this.dataPishingAllPstSrv );

  }

  

  public async cargarAllResultados() {
    
    this.dataAllResultados = [];
    
    let page = 1;

    let elementos = this.dataPishingAllPstSrv.length;
    
    let ele = 0
    
    let Pish: any = {};
    // Aqui
    while (ele < (elementos )) {
      
      Pish = this.dataPishingAllPstSrv[ ele ];

      let continuar = true; 
      
      page = 1;

      while (continuar) {
          
        await this.pishingAllResultadosKnowbe4( Pish.pst_id, page, this.per_page ).then( async (loteReg) => {  
  
          const reg:any[] = loteReg;        
          
          this.contRegistrosDescargados += reg.length ;
          this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
          
          if (reg.length>0) {
  
            reg.forEach( item => {              
  
              this.contRegPreFiltrados += 1 ;
              this.totalRegPreFiltrados.next(this.contRegPreFiltrados);            
  
              this.bitacoraLog.next( 'Procesando : '+item.name );
              this.dataAllResultados.push(item);

            })

            page = page +1;
            continuar =true;

          } else {

            continuar = false;
            ele = ele +1;            
            this.procesoEnCurso.next("Descarga de resultados  un total de "+this.dataAllResultados.length.toString());

          }       
          
        })
        
      }
    }
      
    if ( this,this.dataAllResultados.length>0) {
      
      this.srvAllResultados.next( this.dataAllResultados )
    };
    this.bitacoraLog.next( ' ' );

  }

  public async cargarAllResultadosCache() : Promise <any> {

    return new Promise( async resolve =>{ 

      this.dataAllResultados = [];

      await this.cacheDB_AllResultados.allDocs( { include_docs: true, attachments: true }).then( docs =>{

        const reg:any[] = docs.rows;        
            
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
            
        if (reg.length>0) {
    
          reg.forEach( item => {              
    
            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);            
    
            this.bitacoraLog.next( 'Procesando : '+item.name );
            this.dataAllResultados.push(item.doc.data);

          });
              
          this.procesoEnCurso.next("Carga de resultados de cache un total de "+this.dataAllResultados.length.toString());
          this.bitacoraLog.next( ' ' );              
          this.srvAllResultados.next( this.dataAllResultados )

        }       

        resolve({ok:true});

      })
    })
  }


  public async cargarTraining() {

    let continuar = true;                 
    let page = 1;

    this.dataTrainingSrv = [];
    this.dataTrainingStaSrv = [];
       
    while (continuar) {
        
      await this.trainingKnowbe4(page,this.per_page).then( loteReg => {

        const reg:any[] = loteReg;        
        
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
        
        if (reg.length>0) {

          reg.forEach( item => {    
            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);
            
            this.bitacoraLog.next( 'Procesando : '+item.name );
            this.dataTrainingSrv.push(item);
          })
          page = page +1;
          continuar =true;
        } else {
          continuar = false;
          this.procesoEnCurso.next("Finalizada la descarga de training, un total de "+this.dataTrainingSrv.length.toString());
          this.bitacoraLog.next( ' ' );

          this.dataTrainingSrv.forEach( (p,i) =>{

            if ( this.dataTrainingStaSrv.indexOf( String(p.status).toUpperCase() )< 0 ) {
              this.dataTrainingStaSrv.push(String(p.status).toUpperCase())
            }
      
          })

          
          this.srvTraining.next( this.dataTrainingSrv );
        }       
        
      })
      
    }    
    
  }

  public async cargarTrainingCache() : Promise <any> {

    return new Promise( async resolve =>{ 

      this.dataTrainingSrv = [];
      this.dataTrainingStaSrv = [];      
        
      await this.cacheDB_Trai.allDocs( { include_docs: true, attachments: true }).then( docs =>{ 
  
        const reg:any[] = docs.rows;        
          
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
        
        if (reg.length>0) {

          reg.forEach( item => {

            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);
            
            this.bitacoraLog.next( 'Procesando : '+item.name );
            this.dataTrainingSrv.push(item.doc.data);

          })
          
          this.procesoEnCurso.next("Finalizada la carga de training del cache, un total de "+this.dataTrainingSrv.length.toString());
          this.bitacoraLog.next( ' ' );

          this.dataTrainingSrv.forEach( (p,i) =>{

            if ( this.dataTrainingStaSrv.indexOf( String(p.status).toUpperCase() )< 0 ) {
              this.dataTrainingStaSrv.push(String(p.status).toUpperCase())
            }
      
          })

          // console.log('Training =>',this.dataTrainingSrv);

          this.srvTraining.next( this.dataTrainingSrv );
                    
        }       

        resolve({ok:true});
        
      })

    });
    
  }

  

  public async cargarTrainingUsers() {

    this.dataAlltrainingUsers = [];

    let page = 1;

    let elementos = this.dataTrainingSrv.length;
    let ele = 0;
    let Train:any = {};

    // Aqui
    while ( ele < (elementos)) {

      Train = this.dataTrainingSrv[ ele ];
      
      let continuar = true;                 

      page = 1;

      while (continuar) {
          
        await this.trainingUsersKnowbe4( Train.campaign_id, page, this.per_page ).then( async (loteReg) => {  
  
          const reg:any[] = loteReg;        
          
          this.contRegistrosDescargados += reg.length ;
          this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
          
          if (reg.length>0) {
  
            reg.forEach( item => {              
  
              this.contRegPreFiltrados += 1 ;
              this.totalRegPreFiltrados.next(this.contRegPreFiltrados);            
  
              this.bitacoraLog.next( 'Procesando : '+item.module_name );
              this.dataAlltrainingUsers.push(item);

            })

            page = page +1;
            continuar =true;

          } else {

            continuar = false;

            ele = ele + 1;

            this.procesoEnCurso.next("Descarga de resultados de TRAINING un total de "+this.dataAllResultados.length.toString());            
            
          }       
          
        });
        
      }
      
    }  

    if ( this.dataAlltrainingUsers.length>0) {     
      
      this.dataAlltrainingUsers.forEach( (p,i) =>{

        if ( this.dataAlltrainingStaUsers.indexOf( String(p.status).toUpperCase() )< 0 ) {
          this.dataAlltrainingStaUsers.push(String(p.status).toUpperCase())
        }
  
      })

      this.srvTrainingUsers.next( this.dataAlltrainingUsers )
    };
    this.bitacoraLog.next( ' ' );
    
    
  }

  public async cargarTrainingUsersCache() : Promise <any> {

    return new Promise ( async resolve =>{

      this.dataAlltrainingUsers = [];
      this.dataAlltrainingStaUsers = [];

      await this.cacheDB_TraiUsers.allDocs( { include_docs: true, attachments: true }).then( docs =>{ 
  
        const reg:any[] = docs.rows;        
            
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next( this.contRegistrosDescargados );
            
        if (reg.length>0) {

          reg.forEach( item => {              

            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);            

            this.bitacoraLog.next( 'Procesando : '+item.module_name );
            this.dataAlltrainingUsers.push(item.doc.data);

          })

          this.dataAlltrainingUsers.forEach( (p,i) =>{

            if ( this.dataAlltrainingStaUsers.indexOf( String(p.status).toUpperCase() )< 0 ) {
              this.dataAlltrainingStaUsers.push(String(p.status).toUpperCase())
            }
      
          })
  
          this.srvTrainingUsers.next( this.dataAlltrainingUsers )         
          this.procesoEnCurso.next("Carga de resultados de TRAINING un total de "+this.dataAllResultados.length.toString());            
          this.bitacoraLog.next( ' ' );
          
        }
        
        resolve({ok:true});

      })
    
    })

  }

  

  public async cargarPurchases() {

    let continuar = true;                 
    let page = 1;

    this.dataPurchasesSrv = [];   
    
    while (continuar) {
        
      await this.purchasestrainingKnowbe4(page, this.per_page).then( loteReg => {

        const reg:any[] = loteReg;        
        
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
        
        if (reg.length>0) {

          reg.forEach( item => {    
            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);
            
            this.bitacoraLog.next( 'Procesando : '+item.name );
            this.dataPurchasesSrv.push(item);
          })
          page = page +1;
          continuar =true;
        } else {
          continuar = false;
          this.procesoEnCurso.next("Finalizada la descarga de objetos de entrenamiento, un total de "+this.dataPurchasesSrv.length.toString());
          this.bitacoraLog.next( ' ' );
          this.srvPurchases.next( this.dataPurchasesSrv );
        }       
        
      })
      
    }   
    
  }

  public async cargarPurchasesCache() : Promise<any> {

    return new Promise( async resolve => {
  
      this.dataPurchasesSrv = [];   
      
      await this.cacheDB_Modu.allDocs( { include_docs: true, attachments: true }).then( docs =>{ 
  
        const reg:any[] = docs.rows;           
          
        this.contRegistrosDescargados += reg.length ;
        this.totalRegistrosDescargados.next(this.contRegistrosDescargados);
        
        if (reg.length>0) {

          reg.forEach( item => {    
            this.contRegPreFiltrados += 1 ;
            this.totalRegPreFiltrados.next(this.contRegPreFiltrados);
            
            this.bitacoraLog.next( 'Procesando : '+item.name );
            this.dataPurchasesSrv.push(item.doc.data);
          })
          
          this.procesoEnCurso.next("Finalizada la cargo de cache de objetos de entrenamiento, un total de "+this.dataPurchasesSrv.length.toString());
          this.bitacoraLog.next( ' ' );
          this.srvPurchases.next( this.dataPurchasesSrv );
        } 

        resolve({ok:true});        
        
      })

    })

  }


  public async determinarTablasVinculadas() {

    this.dataGerenciasSrv = [];
    this.dataDepartamentosSrv = [];
    this.dataCargosSrv = []

    this.porcenSubProc.next(0);

    const totReg = this.dataUsuariosSrv.length;    

    this.dataUsuariosSrv.forEach( (e,i) => {      

      if ( this.dataGerenciasSrv.indexOf( String(e.division).toUpperCase() )< 0 ) {
        this.dataGerenciasSrv.push(String(e.division).toUpperCase())
      }

      if ( this.dataDepartamentosSrv.indexOf( String(e.department).toUpperCase() )< 0 ) {
        this.dataDepartamentosSrv.push(String(e.department).toUpperCase())
      }

      if ( this.dataCargosSrv.indexOf( String(e.job_title).toUpperCase() )< 0 ) {
        this.dataCargosSrv.push(String(e.job_title).toUpperCase())
      }

      this.porcenSubProc.next(((i+1)*100)/totReg);
      
    });
    

    this.procesoEnCurso.next("Finalizado la deteccion de Gerencias, Departamentos y Cargos ");
    this.bitacoraLog.next( ' ' );  

    this.srvGerencias.next(this.dataGerenciasSrv);
    this.srvDepartamentos.next(this.dataDepartamentosSrv);
    this.srvCargos.next(this.dataCargosSrv);

    this.srvPisStates.next(this.dataPishingStaSrv);
    this.srvTraStates.next(this.dataTrainingStaSrv);
    this.srvAllResultadosSta.next(this.dataAlltrainingStaUsers);

  }
  
  public async prefiltrarRegistros() {

  }




  public async conectarKnowbe4():Promise<any> {

    return new Promise( async (resolve,reject) =>{

      this.configurarCabecera();
      const ruta = `account`;    
      return await this.http.get(this.url+ruta, this.httpOptions).toPromise().then( (st:any) =>{

        this.Conectado = true;
        this.cliente = st.name;
        this.dominio = st.domains[0];

        if (st.domains.lenght > 1) {
          this.dominio +=' y '+(st.domains.lenght-1).toString()+' dominio adicional'
        }

        this.swConectado.next( this.Conectado );      

        resolve( { ok:true,msg:'Conectado' });

      }).catch( err => {

          this.Conectado = false;
          this.swConectado.next( this.Conectado );
          
          console.log('Error =>',err)

          reject( {ok:false, err:err, msg:'NO conectado' })
          
      });

    })
    
  }

  public async desconectarKnowbe4() {

    this.configurarCabecera();
    const ruta = `account`;    
    

      this.Conectado = true;
      this.cliente = "";
      this.dominio = "";
      this.token = this.defaultToken;
      this.url = this.defaultUrl;
      
      this.swConectado.next( this.Conectado );      

  
  }

  public configurarCabecera() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer '+this.token,
        'Accept' : 'application/json',          
        'Contet-Type': 'application/json',
      })        
    };
  }

  public async syncronizarData(){

    this.cntApi = 0;

    this.swCancelado.next(false);    
    
    this.swCargando.next(true);
    this.swCargado.next(false);

    this.contRegistrosDescargados = 0;
    this.contRegPreFiltrados = 0;
    this.contRegFiltrados = 0;

    this.totalRegistrosDescargados.next(0);
    this.totalRegPreFiltrados.next(0);
    this.totalRegFiltrados.next(0);
    
    this.procesoEnCurso.next("Descarga de usuarios");

    this.porcenGeneral.next(5);

    let Promesas = [];

    await this.cargarUsuarios().then( async sp=>{ this.porcenGeneral.next(30);  });
  
    // console.llog('Consultas =>',this.cntApi);

    await this.cargarGrupos().then( async sp => { this.porcenGeneral.next(35);  });

    // console.llog('Consultas =>',this.cntApi);
    
    await this.cargarPishing().then( async sp =>{ this.porcenGeneral.next(40);  });

    // console.llog('Consultas =>',this.cntApi);
          
    await this.cargarAllPstPishing().then( async sp => { this.porcenGeneral.next(50);  });

    // console.llog('Consultas =>',this.cntApi);
        
    // await this.cargarAllPstXcamp().then( async sp =>{ this.porcenGeneral.next(60); });

    await this.cargarAllResultados().then( async sp =>{  this.porcenGeneral.next(60);  });

    // console.llog('Consultas =>',this.cntApi);

    await this.cargarTraining().then( async sp => {  this.porcenGeneral.next(70);  });

    // console.llog('Consultas =>',this.cntApi);

    await this.cargarPurchases().then( async sp => { this.porcenGeneral.next(80);  });

    // console.llog('Consultas =>',this.cntApi);
    
    await this.cargarTrainingUsers().then( async sp => { this.porcenGeneral.next(90);  });

    console.log('Consultas =>',this.cntApi);

    await this.determinarTablasVinculadas().then( async sp => { this.porcenGeneral.next(100);  });
    
      
    this.swCargado.next(true);
  
    this.swCargando.next(false);
    
  }
  
  public async syncronizarDataCache(){
    

    this.swCancelado.next(false);    
    
    this.swCargando.next(true);
    this.swCargado.next(false);

    this.contRegistrosDescargados = 0;
    this.contRegPreFiltrados = 0;
    this.contRegFiltrados = 0;

    this.totalRegistrosDescargados.next(0);
    this.totalRegPreFiltrados.next(0);
    this.totalRegFiltrados.next(0);
    
    this.procesoEnCurso.next("Sincronizacion de usuarios con data en el cache");

    this.porcenGeneral.next(5);    

    await this.cargarUsuariosCache().then( async sp=>{ this.porcenGeneral.next(30); });
  
    await this.cargarGruposCache().then( async sp => { this.porcenGeneral.next(35); });
    
    await this.cargarPishingCache().then( async sp =>{ this.porcenGeneral.next(40); });
          
    await this.cargarAllPstPishingCache().then( async sp => { this.porcenGeneral.next(50); });
        
    // await this.cargarAllPstXcampCache().then( async sp =>{ this.porcenGeneral.next(60); });

    await this.cargarAllResultadosCache().then( async sp =>{ this.porcenGeneral.next(60); });

    await this.cargarTrainingCache().then( async sp => { this.porcenGeneral.next(70); });

    await this.cargarPurchasesCache().then( async sp => { this.porcenGeneral.next(80); });
    
    await this.cargarTrainingUsersCache().then( async sp => { this.porcenGeneral.next(90); });

    await this.determinarTablasVinculadas().then( async sp => { this.porcenGeneral.next(100); });    
      
    this.swCargado.next(true);
  
    this.swCargando.next(false);
    
  }

  getProcesoEnCurso(): Observable<string> {
    return this.procesoEnCurso.asObservable()    
  }

  getBitacoraLog(): Observable<string> {
    return this.bitacoraLog.asObservable()    
  }

  getTotalRegistrosDescargados(): Observable<Number> {
    return this.totalRegistrosDescargados.asObservable()    
  }
  getTotalRegistrosCache(): Observable<Number> {
    return this.totalRegistrosCache.asObservable()    
  }

  getTotalRegPreFiltrados(): Observable<Number> {
    return this.totalRegPreFiltrados.asObservable()    
  }

  getTotalRegFiltrados(): Observable<Number> {
    return this.totalRegPreFiltrados.asObservable()    
  }

  getPorcentajeGeneral(): Observable<Number> {
    return this.porcenGeneral.asObservable()    
  }

  getPorcentajeSubProceso(): Observable<Number> {
    return this.porcenSubProc.asObservable()    
  }

  getGerencias(): Observable<string[]> {
    return this.srvGerencias.asObservable()    
  }

  getDepartamentos(): Observable<string[]> {
    return this.srvDepartamentos.asObservable()    
  }

  getCargos(): Observable<string[]> {
    return this.srvCargos.asObservable()    
  }

  getGrupos(): Observable<any[]> {
    return this.srvGrupos.asObservable()    
  }

  getPishing(): Observable<any[]> {
    return this.srvPishing.asObservable()    
  }
  getPisStates(): Observable<any[]> {
    return this.srvPisStates.asObservable()    
  }

  getSrvAllPstPishing(): Observable<any[]> {
    return this.srvPishingSrvAllPst.asObservable()    
  }

  getSrvAllResult(): Observable<any[]> {
    return this.srvAllResultados.asObservable()    
  }

  getTraining(): Observable<any[]> {
    return this.srvTraining.asObservable()    
  }

  getTraiStates(): Observable<any[]> {
    return this.srvTraStates.asObservable()    
  }

  getTrainingUsers(): Observable<any[]> {
    return this.srvTrainingUsers.asObservable()    
  }

  getTrainingUsersSta(): Observable<any[]> {
    return this.srvAllResultadosSta.asObservable()    
  }
  
  getPurchases(): Observable<any[]> {
    return this.srvPurchases.asObservable()    
  }

  getUsuarios(): Observable<any[]> {
    return this.srvUsuarios.asObservable()    
  }

  getConectado(): Observable<boolean> {
    return this.swConectado.asObservable()    
  }

  getCargando(): Observable<boolean> {
    return this.swCargando.asObservable()    
  }
  getCargado(): Observable<boolean> {
    return this.swCargado.asObservable()    
  }

  getPreFiltrando(): Observable<boolean> {
    return this.swPrefiltrando.asObservable()    
  }
  getPreFiltrado(): Observable<boolean> {
    return this.swPrefiltrado.asObservable()    
  }

  getFiltrando(): Observable<boolean> {
    return this.swFiltrando.asObservable()    
  }

  getFiltrado(): Observable<boolean> {
    return this.swFiltrado.asObservable()    
  }

  getCancelado(): Observable<boolean> {
    return this.swCancelado.asObservable()    
  }

  getTimer(): Observable<string> {
    return this.swTimer.asObservable()    
  }

  setCancelado(sw:boolean) {    
    this.swCancelado.next(sw);
  }

  resetCounter(){
    this.porcenGeneral.next(0);
    this.totalRegistrosDescargados.next(0);
    this.totalRegPreFiltrados.next(0);
    this.totalRegFiltrados.next(0);
    

  }

  async gravarCache() {

    this.porcenGeneral.next(0);
    
    this.procesoEnCurso.next("Iniciando escritura de Cache ");
    
    this.porcenGeneral.next(10);

    this.cacheDB_Conf = new PouchDB("Conf");
    this.cacheDB_Site = new PouchDB("Site");
    this.cacheDB_User = new PouchDB("Usuarios");
    this.cacheDB_Grup = new PouchDB("Grupos");    
    this.cacheDB_Trai = new PouchDB("Training");
    this.cacheDB_Pish = new PouchDB("Piching");
    this.cacheDB_Modu = new PouchDB("Modulos");
    this.cacheDB_PishAllPst = new PouchDB("Piching-PST");
    this.cacheDB_PishAllPstxCamp = new PouchDB("Piching-PST-xcamp");
    this.cacheDB_AllResultados = new PouchDB("Piching-All-Resultados");
    this.cacheDB_TraiUsers = new PouchDB("Training-xUser");    

    this.porcenGeneral.next(20);    

    let c = 0;

    // Usuarios    

    this.procesoEnCurso.next("Escribiendo data de Usuario ");

    c = 0;

    while (c < this.dataUsuariosSrv.length ) {

      const r: any = this.dataUsuariosSrv[c];
      
      const _id = r.id.toString();
      
      await this.cacheDB_User.get( _id ).then( async doc => {

        if ( doc.ok ) {
          return await this.cacheDB_User.put({
            _id: doc._id,
            _rev: doc._rev,
            data: r
          });
        }

        c++;
        
      }).catch( async (err) => {

        await this.cacheDB_User.put({
          _id: _id,          
          data: r
        }).then( st =>{
          c++;
        });

      });      

    };    

    this.loadInfoCache();

    this.porcenGeneral.next(30);

    // Grupos

    this.procesoEnCurso.next("Escribiendo data de Grupos ");

    c=0;

    while (c < this.dataGruposSrv.length ) { 
      
      const r: any = this.dataGruposSrv[c];

      const _id = r.id.toString();
      
      await this.cacheDB_Grup.get( _id ).then( async doc => {

        if ( doc.ok ) {
          return await this.cacheDB_Grup.put({
            _id: doc._id,
            _rev: doc._rev,
            data: r
          });          
        }

        c++;
        
      }).catch( async (err) => {

        await this.cacheDB_Grup.put({
          _id: _id,          
          data: r
        }).then( st =>{
          c++;
        });

      });

    };

    this.loadInfoCache();

    this.porcenGeneral.next(40);

    this.procesoEnCurso.next("Escribiendo data de Pishing ");

    c=0;

    while (c < this.dataPishingSrv.length ) { 
      
      const r: any = this.dataPishingSrv[c];

      const _id = r.campaign_id.toString();
      
      await this.cacheDB_Pish.get( _id ).then( async doc => {

        if ( doc.ok ) {
          return await this.cacheDB_Pish.put({
            _id: doc._id,
            _rev: doc._rev,
            data: r
          });          
        }

        c++;
        
      }).catch( async (err) => {

        await this.cacheDB_Pish.put({
          _id: _id,          
          data: r
        }).then( st =>{
          c++;
        });

      });

    };

    this.loadInfoCache();

    this.procesoEnCurso.next("Escribiendo data de Pishing PST ");

    this.porcenGeneral.next(50);    
    
    c=0;
// TODO revisar el vector
    while (c < this.dataPishingAllPstSrv.length ) { 
      
      const r: any = this.dataPishingAllPstSrv[c];

      const _id = r.campaign_id.toString();
      
      await this.cacheDB_PishAllPst.get( _id ).then( async doc => {

        if ( doc.ok ) {
          return await this.cacheDB_PishAllPst.put({
            _id: doc._id,
            _rev: doc._rev,
            data: r
          });          
        }

        c++;
        
      }).catch( async (err) => {

        await this.cacheDB_PishAllPst.put({
          _id: _id,          
          data: r
        }).then( st =>{
          c++;
        });

      });

    };

    this.loadInfoCache();

    this.porcenGeneral.next(60);

    this.procesoEnCurso.next("Escribiendo data de Resultados ");

    c=0;

    while (c < this.dataAllResultados.length ) { 
      
      const r: any = this.dataAllResultados[c];

      const _id = r.recipient_id.toString();
      
      await this.cacheDB_AllResultados.get( _id ).then( async doc => {

        if ( doc.ok ) {
          return await this.cacheDB_AllResultados.put({
            _id: doc._id,
            _rev: doc._rev,
            data: r
          });          
        }

        c++;
        
      }).catch( async (err) => {

        await this.cacheDB_AllResultados.put({
          _id: _id,          
          data: r
        }).then( st =>{
          c++;
        });

      });

    };

    this.loadInfoCache();

    this.porcenGeneral.next(70);

    this.procesoEnCurso.next("Escribiendo data de Training ");

    c=0;

    while (c < this.dataTrainingSrv.length ) { 
      
      const r: any = this.dataTrainingSrv[c];      

      const _id = r.campaign_id.toString();
      
      await this.cacheDB_Trai.get( _id ).then( async doc => {

        if ( doc.ok ) {
          return await this.cacheDB_Trai.put({
            _id: doc._id,
            _rev: doc._rev,
            data: r
          });          
        }

        c++;
        
      }).catch( async (err) => {

        await this.cacheDB_Trai.put({
          _id: _id,          
          data: r
        }).then( st =>{
          c++;
        });

      });

    };

    this.loadInfoCache();

    this.porcenGeneral.next(80);

    this.procesoEnCurso.next("Escribiendo data de Modulos ");   
    

    c=0;

    while (c < this.dataPurchasesSrv.length ) { 
      
      const r: any = this.dataPurchasesSrv[c];

      const _id = r.store_purchase_id.toString();
      
      await this.cacheDB_Modu.get( _id ).then( async doc => {

        if ( doc.ok ) {
          return await this.cacheDB_Modu.put({
            _id: doc._id,
            _rev: doc._rev,
            data: r
          });          
        }

        c++;
        
      }).catch( async (err) => {

        await this.cacheDB_Modu.put({
          _id: _id,          
          data: r
        }).then( st =>{
          c++;
        });

      });

    };

    this.loadInfoCache();

    this.porcenGeneral.next(90);

    this.procesoEnCurso.next("Escribiendo data de training por usuario ");

    c=0;

    while (c < this.dataAlltrainingUsers.length ) { 
      
      const r: any = this.dataAlltrainingUsers[c];

      const _id = r.enrollment_id.toString();
      
      await this.cacheDB_TraiUsers.get( _id ).then( async doc => {

        if ( doc.ok ) {
          return await this.cacheDB_TraiUsers.put({
            _id: doc._id,
            _rev: doc._rev,
            data: r
          });          
        }

        c++;
        
      }).catch( async (err) => {

        await this.cacheDB_TraiUsers.put({
          _id: _id,          
          data: r
        }).then( st =>{
          c++;
        });

      });

    };

    this.loadInfoCache();    

    this.procesoEnCurso.next("Finalizado escritura de cache ");

    this.porcenGeneral.next(100);

    

  }

  async loadInfoCache(){    

    this.contRegistrosCache = 0;
    this.BD = [];
    
    await this.cacheDB_User.info().then( r =>{      
      this.contRegistrosCache += r.doc_count;
      this.BD.push({ ...r});      
      this.totalRegistrosCache.next( this.contRegistrosCache);
    })

    await this.cacheDB_Grup.info().then( r =>{      
      this.contRegistrosCache += r.doc_count;
      this.BD.push({ ...r});
      this.totalRegistrosCache.next( this.contRegistrosCache);
    })

    await this.cacheDB_Trai.info().then( r =>{      
      this.contRegistrosCache += r.doc_count;
      this.BD.push({ ...r});
      this.totalRegistrosCache.next( this.contRegistrosCache);
    })

    await this.cacheDB_Pish.info().then( r =>{      
      this.contRegistrosCache += r.doc_count;
      this.BD.push({ ...r});
      this.totalRegistrosCache.next( this.contRegistrosCache);
    })

    await this.cacheDB_Modu.info().then( r =>{      
      this.contRegistrosCache += r.doc_count;
      this.BD.push({ ...r});
      this.totalRegistrosCache.next( this.contRegistrosCache);
    })

    await this.cacheDB_PishAllPst.info().then( r =>{      
      this.contRegistrosCache += r.doc_count;
      this.BD.push({ ...r});
      this.totalRegistrosCache.next( this.contRegistrosCache);
    })

    await this.cacheDB_PishAllPstxCamp.info().then( r =>{      
      this.contRegistrosCache += r.doc_count;
      this.BD.push({ ...r});
      this.totalRegistrosCache.next( this.contRegistrosCache);
    })

    await this.cacheDB_AllResultados.info().then( r =>{      
      this.contRegistrosCache += r.doc_count;
      this.BD.push({ ...r});
      this.totalRegistrosCache.next( this.contRegistrosCache);
    })

    await this.cacheDB_TraiUsers.info().then( r =>{      
      this.contRegistrosCache += r.doc_count;
      this.BD.push({ ...r});
      this.totalRegistrosCache.next( this.contRegistrosCache);
    })

  }

}
