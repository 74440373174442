// cSpell:disable
import { Component, OnDestroy ,OnInit, ViewChild } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateStruct, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Knowbe4ServiceService } from '../knowbe4-service.service';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';


import { CountdownTimerService, countUpTimerConfigModel, timerTexts } from 'ngx-timer';

import {NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { isNull } from 'util';




@Component({
  selector: 'app-exportar-excel',
  templateUrl: './exportar-excel.component.html',
  styleUrls: ['./exportar-excel.component.css']
})
export class ExportarExcelComponent implements OnDestroy, OnInit {

  // Formulario


  revision : string = 'V-0.006 - 18/04/2022';

  exportKnowbe4 : FormGroup;


  hoveredDate: NgbDate | null = null;
  hoveredDateVig: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  
  fromDateVig: NgbDate;
  toDateVig: NgbDate | null = null;

  minDate: NgbDate;
  iniDate: NgbDate;
  maxDate: NgbDate;

  minDateVig: NgbDate;
  iniDateVig: NgbDate;
  maxDateVig: NgbDate;

  swPeriodoVig: number = 4;
  // 0 Un periodo
  // 1 Treinta dias
  // 2 tres meses
  // 3 seis meses
  // 4 Todo 

  swPaso : number = 0;
  // 0 Configuracion
  // 1 Cargar datos
  // 2 Filtros
  // 3 Exportar

  // Timer

  tabSet : number = 1;
  pageSet : number = 1;
  page=1;

  testConfig: any;
  timeLeft: number = 0;
  interval;
  cdate = new Date();

  swConectado : boolean = false;
  swCache : boolean = false;
  swCacheUso : boolean = false;
  swSincronizado: boolean = false;
  swFiltrado : boolean = false;
  swFiltrando : boolean = false;
  swCargado : boolean = false;
  swCargando: boolean = false;
  swCancelado: boolean = false;
  swProcesando: boolean = false;
  swVinculada: boolean = false;

  enProceso : string = 'Haga click en "Sincronizar para dar inicio al proceso ...';
  bitLog : string = 'Aqui detalles de la accion en curso ...';

  porGeneral: Number = 0;
  porSubproc: Number = 0;
  porFiltrar: Number = 0;

  totRegCache : Number = 0;
  totRegDescargados : Number = 0;
  totRegPreFiltrados : Number = 0 ;  
  totRegFiltrados : Number = 0;


  cliente = '';
  usuarios = [];

  gerencias = [];
  gerenciasOpc = [];
  swGerencias : any[] = [];
  swModSelGer: 0;

  swModSelModulo = 0;
  swModSelPishin = 0;
  swModSelTraini = 0;



  status_cur = [];
  status_curOpc = [];
  swStatus_cur = [];

  status_pis = [];
  status_pisOpc = [];
  swStatus_pis = [];

  departamentos = [];
  departamentosOpc = [];
  swDepartamentos : any[] = [];

  cargos = [];
  cargosOpc = [];
  swCargos : any[] = [];

  grupos : any[] = [];
  gruposOpc : any[] = [];
  swGrupos : any[] = [];

  training : any[] = [];
  trainingOpc : any[] = [];
  swTraining : any[] = [];

  trainingUsers : any[] = [];

  purchases : any[] = [];
  purchasesOpc : any[] = [];
  swPurchases : any[] = [];

  sta_purchases : any[] = [];
  sta_purchasesOpc : any[] = [];
  swSta_purchases : any[] = [];

  pishing : any[] = [];
  pishingOpc : any[] = [];
  swPishing : any[] = [];


  allPstPishing : any[] = [];
  allResults : any[] = [];

  xlsFiltros : any[] = [];
  xlsUsuarios : any[] = [];
  xlsGrupos : any[] = [];
  xlsUserGroup : any[] = [];
  xlsUserPish : any[] = [];
  xlsTraining : any[] = [];
  xlsPishing : any[] = [];
  xlsRecursos : any[] = [];
  xlsResPisshing : any[] = [];
  xlsResTraining : any[] = [];

  data: any[] = [ 
    
  ];

  dataF: any[] = [];
  dataB: any[] = [];

  dataExcluidosPishing: any[] = [];
  dataExcluidosTraining: any[] = [];
  dataExcluidosModulos: any[] = [];

  pagePw = 1;
  pagePwSize= 10;
  dataPw = [];
  collectionSize = 0;
  encontrados = 0;
  infoTable = "Encontrados : "+this.encontrados;

  filter = new FormControl('');

  fileName= 'Exportacion_KnowBe4_Data.xlsx'
  fileNameFiltro= 'Exportacion_KnowBe4_Filtrada.xlsx'

  // Test multiples optiones

  modos = [" AND ", " OR "];

  exclusion = [ 
    { valor: 0, opcion: "Incluidos"} ,
    { valor: 1, opcion: "Excluidos"} ,
  ];

  

  


  model: NgbDateStruct;
  
  constructor( 
                private calendar: NgbCalendar,
                private knowbe4Service: Knowbe4ServiceService,
                private modalService: NgbModal,
                private CountdownTimerService:CountdownTimerService
               ) {
    
    this.knowbe4Service.getConectado().subscribe( estado =>{ this.swConectado = estado; });
    this.knowbe4Service.getFiltrando().subscribe( estado =>{ this.swFiltrando = estado; });
    this.knowbe4Service.getFiltrado().subscribe( estado =>{ this.swFiltrado = estado; });
    this.knowbe4Service.getCargando().subscribe( estado =>{ 
      
      this.swCargando = estado; 
    });
    this.knowbe4Service.getCargado().subscribe( estado =>{ 
      if ( estado ) this.swPaso=2;
      this.swCargado = estado; 
    });

    this.knowbe4Service.getTotalRegistrosCache().subscribe( totCache =>{
      this.totRegCache = totCache;
      this.swCache = ( this.totRegCache>0 );
    })

    this.knowbe4Service.getCargado().subscribe( estado =>{ this.swCargado = estado; });    
    
    this.fromDate = calendar.getPrev( calendar.getToday(), 'm', 1);
    this.toDate = calendar.getToday();

    this.fromDateVig = calendar.getPrev( calendar.getToday(), 'm', 12);
    this.toDateVig = calendar.getToday();

    this.iniDate = calendar.getPrev( calendar.getToday(), 'd', 30);
    this.minDate = knowbe4Service.dataDesde;
    this.maxDate = knowbe4Service.dataHasta;

    this.iniDateVig = calendar.getPrev( calendar.getToday(), 'd', 30);
    this.minDateVig = knowbe4Service.dataDesde;
    this.maxDateVig = knowbe4Service.dataHasta;

    this.exportKnowbe4 = new FormGroup({
      'cliente': new FormControl(),
      'dominio': new FormControl(),
      'url': new FormControl(),
      'token': new FormControl(),
      'registros': new FormControl(),
      'volumen': new FormControl(),
      'session': new FormControl(),
      'rango' : new FormControl(),
      'buscar' : new FormControl(),
      'desde': new FormControl(),
      'hasta': new FormControl(),
      'procesando': new FormControl(),      
      
      'grupo': new FormControl(),
      
      'pagePwSize': new FormControl(),
      
      'recurso': new FormControl(),
      'est_recurso': new FormControl(),
      
      'idpishing': new FormControl(),
      'est_pish': new FormControl(),
      'swpishing': new FormControl(),
      
      'idtraining': new FormControl(),
      'swtraining': new FormControl(),
      'est_trai': new FormControl(),
      
      'swDepartamentos': new FormControl(),      
      'swCargos': new FormControl(),
      'swGerencias': new FormControl(),
      'swGrupos': new FormControl(),
      'swPurchases': new FormControl(),
      'swSta_purchases': new FormControl(),
      'swPishing': new FormControl(),
      'swTraining': new FormControl(),
      'swStatus_cur': new FormControl(),
      'swStatus_pis': new FormControl(),
      'swModSelModulo': new FormControl(),
      'swModSelPishin': new FormControl(),
      'swModSelTraini': new FormControl(),
      'swModSelGer': new FormControl()
      
      
    });

    

    this.exportKnowbe4.controls['cliente'].setValue('Sin especificar');
    this.exportKnowbe4.controls['dominio'].setValue('???.??');
    this.exportKnowbe4.controls['url'].setValue(this.knowbe4Service.url);
    this.exportKnowbe4.controls['token'].setValue(this.knowbe4Service.token);
    this.exportKnowbe4.controls['registros'].setValue(1);
    this.exportKnowbe4.controls['volumen'].setValue(500);
    this.exportKnowbe4.controls['session'].setValue(48);

    this.exportKnowbe4.controls['pagePwSize'].setValue(10);

    this.exportKnowbe4.controls['recurso'].setValue(-1);
    this.exportKnowbe4.controls['est_recurso'].setValue("TODOS");

    
    this.exportKnowbe4.controls['swGrupos'].setValue([-1]);
    this.exportKnowbe4.controls['swPurchases'].setValue([-1]);
    this.exportKnowbe4.controls['swCargos'].setValue([0]);
    this.exportKnowbe4.controls['swGerencias'].setValue([0]);
    this.exportKnowbe4.controls['swDepartamentos'].setValue([0]);
    this.exportKnowbe4.controls['swSta_purchases'].setValue(["TODOS"]);
    this.exportKnowbe4.controls['swStatus_cur'].setValue(["TODOS"]);
    this.exportKnowbe4.controls['swStatus_pis'].setValue(["TODOS"]);
    this.exportKnowbe4.controls['swPishing'].setValue([-1]);
    this.exportKnowbe4.controls['swTraining'].setValue([-1]);
    
    this.exportKnowbe4.controls['idpishing'].setValue(-1);
    this.exportKnowbe4.controls['swpishing'].setValue(false);
    this.exportKnowbe4.controls['est_pish'].setValue("TODOS");
    this.exportKnowbe4.controls['idtraining'].setValue(-1);
    this.exportKnowbe4.controls['swtraining'].setValue(false);
    this.exportKnowbe4.controls['est_trai'].setValue("TODOS");    

    this.exportKnowbe4.controls['swModSelGer'].setValue(0);    

    this.exportKnowbe4.controls['swModSelModulo'].setValue(0);    
    this.exportKnowbe4.controls['swModSelPishin'].setValue(0);    
    this.exportKnowbe4.controls['swModSelTraini'].setValue(0);   
    
    

    this.exportKnowbe4.controls['buscar'].setValue('');  
    
    this.exportKnowbe4.controls['swModSelModulo'].disable();    
    this.exportKnowbe4.controls['swModSelPishin'].disable();    
    this.exportKnowbe4.controls['swModSelTraini'].disable();

  }

  ngOnInit(): void {

    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass  = 'test_Timer_class';
    //timer text values  
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = " :"; //default - hh
    this.testConfig.timerTexts.minuteText = " :"; //default - mm
    this.testConfig.timerTexts.secondsText = " s"; //default - ss

    
    
  } 

  debug( form: NgForm ) {
    // console.llog('Formulario', form)
  }

  incluirExcluidosModulos() {
  
    this.exportKnowbe4.controls['swModSelPishin'].setValue(0);    
    this.exportKnowbe4.controls['swModSelTraini'].setValue(0);

    this.exportKnowbe4.controls['swSta_purchases'].setValue(["TODOS"]);
    

  }

  incluirExcluidosTraining( ) {

    this.exportKnowbe4.controls['swModSelModulo'].setValue(0);    
    this.exportKnowbe4.controls['swModSelPishin'].setValue(0);        

    this.exportKnowbe4.controls['swStatus_cur'].setValue(["TODOS"]);

  }

  incluirExcluidosPishing( ) {

    this.exportKnowbe4.controls['swModSelModulo'].setValue(0);            
    this.exportKnowbe4.controls['swModSelTraini'].setValue(0);

    this.exportKnowbe4.controls['swStatus_pis'].setValue(["TODOS"]);    

  }

  checkSiTodosEstan(exportKnowbe4,vector,valorTodos ) {    
    if (exportKnowbe4.value[vector]!= undefined && exportKnowbe4.value[vector].length > 0 ) {

      if ( exportKnowbe4.value[vector].indexOf( parseInt(valorTodos) ) >=0 ) {
        this.exportKnowbe4.controls[vector].setValue([valorTodos])
        if (vector == 'swPurchases') { 
          this.exportKnowbe4.controls['swModSelModulo'].setValue(0);        
          this.exportKnowbe4.controls['swModSelModulo'].disable(); 
        }
        if (vector == 'swPishing') { 
          this.exportKnowbe4.controls['swModSelPishin'].setValue(0);        
          this.exportKnowbe4.controls['swModSelPishin'].disable();  
        }
        if (vector == 'swTraining') { 
          this.exportKnowbe4.controls['swModSelTraini'].setValue(0);
          this.exportKnowbe4.controls['swModSelTraini'].disable(); 
        }
      } else {
        if (vector == 'swPurchases') { this.exportKnowbe4.controls['swModSelModulo'].enable(); }
        if (vector == 'swPishing') { this.exportKnowbe4.controls['swModSelPishin'].enable();  }
        if (vector == 'swTraining') { this.exportKnowbe4.controls['swModSelTraini'].enable(); }
      }

    }
  }

  enableExclModules( exportKnowbe4,vector,valorTodos ) {

    if (exportKnowbe4.value[vector]!= undefined && exportKnowbe4.value[vector].length > 0 ) {
      if ( exportKnowbe4.value[vector].indexOf( parseInt(valorTodos) )==0 ) {
        this.exportKnowbe4.controls['swModSelModulo'].enable();
      } else {
        this.exportKnowbe4.controls['swModSelModulo'].disable();
      }
      this.refreshPage();
    }

  }

  checkSiTodosEstanString(exportKnowbe4,vector,valorTodos ) {    
    if (exportKnowbe4.value[vector]!= undefined && exportKnowbe4.value[vector].length > 0 ) {
      if ( exportKnowbe4.value[vector].indexOf( valorTodos ) >=0 ) {
        this.exportKnowbe4.controls[vector].setValue([valorTodos])
      } 
    }
  }

  Hayfiltro(exportKnowbe4,vector,valorTodos) {
    let hayFiltro = false;
    if (exportKnowbe4.controls[vector].value!= undefined && exportKnowbe4.controls[vector].value.length > 0 ) {
      if ( exportKnowbe4.controls[vector].value.indexOf( parseInt(valorTodos) ) >=0 ) {
        hayFiltro = false;
      } else {
        hayFiltro = true;
      }
    } else {
      this.exportKnowbe4.controls[vector].setValue([valorTodos])
    }    
    return hayFiltro
  }

  HayfiltroString(exportKnowbe4,vector,valorTodos) {
    let hayFiltro = false;
    if (exportKnowbe4.controls[vector].value!= undefined && exportKnowbe4.controls[vector].value.length > 0 ) {
      if ( exportKnowbe4.controls[vector].value.indexOf( valorTodos ) >=0 ) {
        hayFiltro = false;
      } else {
        hayFiltro = true;
      }
    } else {
      this.exportKnowbe4.controls[vector].setValue([valorTodos])
    }
    return hayFiltro
  }


  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  onDateSelectionVig(date: NgbDate) {
    if (!this.fromDateVig && !this.toDateVig) {
      this.fromDateVig = date;
    } else if (this.fromDateVig && !this.toDateVig && date.after(this.fromDateVig)) {
      this.toDateVig = date;
    } else {
      this.toDateVig = null;
      this.fromDateVig = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  isHoveredVig(date: NgbDate) {
    return this.fromDateVig && !this.toDateVig && this.hoveredDateVig && date.after(this.fromDateVig) && date.before(this.hoveredDateVig);
  }

  isInsideVig(date: NgbDate) {
    return this.toDateVig && date.after(this.fromDateVig) && date.before(this.toDateVig);
  }

  isRangeVig(date: NgbDate) {
    return date.equals(this.fromDateVig) || (this.toDateVig && date.equals(this.toDateVig)) || this.isInsideVig(date) || this.isHoveredVig(date);
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  selectTreintaDiasVig() {
    this.swPeriodoVig=1;
    this.fromDate = this.calendar.getPrev( this.calendar.getToday(), 'd', 30);
    this.toDate = this.calendar.getToday();
    this.iniDate = this.calendar.getPrev( this.fromDate, 'd', 30);    
  }

  selectTresMesesVig() {
    this.swPeriodoVig=2;
    this.fromDate = this.calendar.getPrev( this.calendar.getToday(), 'd', 90);
    this.toDate = this.calendar.getToday();
    this.iniDate = this.calendar.getPrev( this.toDate, 'd', 90);

  }
  selectSeisMesesVig() {
    this.swPeriodoVig=3;
    this.fromDate = this.calendar.getPrev( this.calendar.getToday(), 'd', 180);
    this.toDate = this.calendar.getToday();
    this.iniDate = this.calendar.getPrev( this.toDate, 'd', 90);

  }
  selectTodoMesesVig() {
    this.swPeriodoVig=4;
    this.fromDate = this.calendar.getPrev( this.calendar.getToday(), 'm', 12);
    this.toDate = this.calendar.getToday();
    this.iniDate = this.calendar.getPrev( this.toDate, 'd', 90);
  }

  activarPeriodoVig() {
    this.swPeriodoVig=0;
  }

  conectarKnowbe4(){

    this.knowbe4Service.conectarKnowbe4().then( loged =>{

      if ( loged.ok ) {

        this.cliente = this.knowbe4Service.cliente;
  
        this.exportKnowbe4.controls['cliente'].setValue(this.cliente);
        this.exportKnowbe4.controls['dominio'].setValue(this.knowbe4Service.dominio);
        this.exportKnowbe4.controls['url'].disable();
        this.exportKnowbe4.controls['token'].disable();
        this.exportKnowbe4.controls['registros'].disable();
        this.exportKnowbe4.controls['volumen'].disable();
        this.exportKnowbe4.controls['session'].disable();
        this.exportKnowbe4.controls['rango'].disable();
  
        this.cdate = new Date();
        this.cdate.setHours( this.cdate.getHours() + Number(this.exportKnowbe4.controls['session'].value) );
  
        this.CountdownTimerService.startTimer(this.cdate);
  
        this.knowbe4Service.swConectado.next(true);
  
        this.CountdownTimerService.onTimerStatusChange.subscribe(status=>{
          
          if(status){          
            if (status='STOP' && !this.swConectado ) {            
              
              
            }
          }
          
        });
  
        this.knowbe4Service.per_page = Number(this.exportKnowbe4.controls['volumen'].value);
        this.swPaso=1;
      } else {
        // console.llog("no se pudo conectar 1");  

      }



    }).catch(async err=>{
      // console.llog("no se pudo conectar");
      await Swal.fire({
        toast: true,
        position: 'top',
        showConfirmButton: true,
        width: '38%',
        icon: 'error',
        timerProgressBar: true,
        timer: 6000,
        title: 'No pudimos conectarnos'
      })

    })
    
  }

  updateToken() {
    this.knowbe4Service.token= this.exportKnowbe4.controls['token'].value;    
  }

  desconectarKnowbe4(){
    this.exportKnowbe4.controls['cliente'].setValue('Sin especificar');
    this.exportKnowbe4.controls['dominio'].setValue('???.??');
    this.exportKnowbe4.controls['url'].enable();
    this.exportKnowbe4.controls['token'].enable();
    this.exportKnowbe4.controls['registros'].enable();
    this.exportKnowbe4.controls['volumen'].enable();
    this.exportKnowbe4.controls['session'].enable();
    this.exportKnowbe4.controls['rango'].enable();    
    this.swPaso=0;
    this.swSincronizado=this.swConectado=this.swCargado=this.swFiltrado=false;

    this.usuarios=[];
    this.grupos=[];
    this.training=[];
    this.trainingUsers=[];
    this.pishing=[];
    this.allPstPishing=[];
    this.allResults=[];
    this.purchases=[];
    
    this.totRegDescargados = 0;

    this.knowbe4Service.totalRegPreFiltrados.next(this.totRegDescargados);

    this.CountdownTimerService.stopTimer(); 
    this.knowbe4Service.swConectado.next(false);
    
  }

  setGetsSincronizacion() {

    this.swProcesando = true;

    this.swPaso=1;
    
    this.porGeneral = 0;
    this.porSubproc = 0;

    this.totRegDescargados = 0;
    this.totRegPreFiltrados = 0;
    this.totRegFiltrados = 0;

    this.knowbe4Service.getProcesoEnCurso().subscribe( i =>{ this.enProceso = i; });
    
    this.knowbe4Service.getBitacoraLog().subscribe( log =>{ this.bitLog = log; });
    
    this.knowbe4Service.getPorcentajeGeneral().subscribe( pg =>{ this.porGeneral = pg });
    
    this.knowbe4Service.getPorcentajeSubProceso().subscribe( psp =>{ this.porSubproc = psp });
    
    this.knowbe4Service.getTotalRegistrosDescargados().subscribe( rc => { this.totRegDescargados = rc });
    
    this.knowbe4Service.getTotalRegPreFiltrados().subscribe( trpf => { this.totRegPreFiltrados = trpf });
    
    this.knowbe4Service.getGerencias().subscribe( ger => { 

      this.gerenciasOpc=[];
      this.gerencias = ger 
      this.gerenciasOpc.push( { id:0, name:"Todas las gerencias" })
      this.gerencias.forEach( (e,i) => {
        this.gerenciasOpc.push( { id:i+1, name:e })
      })
      
    });    
    
    this.knowbe4Service.getDepartamentos().subscribe( dpto => { 
      
      this.departamentosOpc=[];
      this.departamentos = dpto       
      this.departamentosOpc.push( { id:0, name:"Todos departamentos" })
      this.departamentos.forEach( (e,i) => {
        this.departamentosOpc.push( { id:i+1, name:e })
      })

    });
    
    this.knowbe4Service.getCargos().subscribe( carg => { 
      this.cargosOpc=[];
      this.cargos = carg       
      this.cargosOpc.push( { id:0, name:"Todos cargos" })
      this.cargos.forEach( (e,i) => {
        this.cargosOpc.push( { id:i+1, name:e })
      })
    });

    this.knowbe4Service.getPisStates().subscribe( pStates => { 
      this.swStatus_pis=[];
      this.status_pis = pStates      
      this.status_pisOpc.push( { id:"TODOS", name:"Todos los estados" })
      this.status_pis.forEach( (e,i) => {
        this.status_pisOpc.push( { id:(e as string).toUpperCase(), name:(e as string).toUpperCase() })
      }) 

    });

    this.knowbe4Service.getTraiStates().subscribe( pStates => { 

      this.swStatus_cur=[];
      this.status_cur = pStates       
      this.status_curOpc.push( { id:"TODOS", name:"Todos los estados" })
      this.status_cur.forEach( (e,i) => {
        this.status_curOpc.push( { id:(e as string).toUpperCase(), name:(e as string).toUpperCase() })
      })

    });

    this.knowbe4Service.getTrainingUsersSta().subscribe( pStates => { 
      this.sta_purchasesOpc=[];
      this.sta_purchases = pStates       
      this.sta_purchasesOpc.push( { id:"TODOS", name:"Todos los estados de aprobaciion del modulo" })
      this.sta_purchases.forEach( (e,i) => {
        this.sta_purchasesOpc.push( { id:(e as string).toUpperCase(), name:(e as string).toUpperCase() })
      })
    });

    this.knowbe4Service.getGrupos().subscribe( grupos => { 
      this.gruposOpc=[];
      this.grupos = grupos; 
      this.gruposOpc.push( { id:-1, name:"Todos los grupos" })
      this.grupos.forEach( (e,i) => {
        this.gruposOpc.push( { id:e.id, name:e.name })
      })
    });
    
    this.knowbe4Service.getTraining().subscribe( train => { 
      this.trainingOpc=[];
      this.training = train; 
      
      this.trainingOpc.push( { campaign_id:-1, name:"Todos los entrenamientos" })
      this.training.forEach( (e,i) => {
        this.trainingOpc.push( { campaign_id:e.campaign_id, name:e.name })
      })
    });

    this.knowbe4Service.getPurchases().subscribe( data => { 
      this.purchasesOpc=[];
      this.purchases = data        
      this.purchasesOpc.push( { store_purchase_id:-1, name:"Todos los modulos" })
      this.purchases.forEach( (e,i) => {
        this.purchasesOpc.push( { store_purchase_id:e.store_purchase_id, name:e.name })
      })
    });

    this.knowbe4Service.getPishing().subscribe( pish => {  
      this.pishingOpc=[];
      this.pishing = pish       
      this.pishingOpc.push( { campaign_id:-1, name:"Todos las campañas de pishing" })
      this.pishing.forEach( (e,i) => {
        this.pishingOpc.push( { campaign_id:e.campaign_id, name:e.name })
      })
    });

    this.knowbe4Service.getSrvAllPstPishing().subscribe( pishAll => { 
      
      this.allPstPishing = pishAll 

    });

    this.knowbe4Service.getSrvAllResult().subscribe( pishAll => { 
      
      this.allResults = pishAll ;
    });

    this.knowbe4Service.getTrainingUsers().subscribe( trainingUsers => { 
      this.trainingUsers = trainingUsers       
    });

    this.knowbe4Service.getUsuarios().subscribe( users => { this.usuarios = users });

  }

  sincronizarKnowbe4(){
    
    this.setGetsSincronizacion();    

    this.knowbe4Service.syncronizarData().then( r =>{
      
      this.data = [];      

      this.usuarios.forEach( user => {        

        this.data.push(

          {
            id : user.id,
            nombre : String(user.first_name+' '+user.last_name).toUpperCase(),
            cargo : String(user.job_title).toUpperCase(),
            dpto : String(user.department).toUpperCase(),
            gcia : String(user.division).toUpperCase(),
            email : user.email,
            grupos : this.gruposUsuario( user.groups ),
            pishing : this.resultadosPishing( user.id ),
            training : this.resultadosTraining( user.id )
          }

        );  

      })

      this.dataF = this.data;

      this.refreshPage();

      this.swProcesando = false;

      this.swSincronizado = true;

      this.knowbe4Service.procesoEnCurso.next("Finalizada la sincronizacion");
      this.knowbe4Service.porcenSubProc.next(0);
      

    });       
    
  }

  sincronizarCacheKnowbe4() {

    this.setGetsSincronizacion();

    this.knowbe4Service.syncronizarDataCache().then( r =>{
      
      this.data = [];

      this.usuarios.forEach( user => {        

        this.data.push(

          {
            id : user.id,
            nombre : String(user.first_name+' '+user.last_name).toUpperCase(),
            cargo : String(user.job_title).toUpperCase(),
            dpto : String(user.department).toUpperCase(),
            gcia : String(user.division).toUpperCase(),
            email : user.email,
            grupos : this.gruposUsuario( user.groups ),
            pishing : this.resultadosPishing( user.id ),
            training : this.resultadosTraining( user.id )
          }

        );                 

      });

      this.dataF = this.data;

      this.refreshPage();

      this.swProcesando = false;

      this.knowbe4Service.procesoEnCurso.next("Finalizada la sincronizacion");
      this.knowbe4Service.porcenSubProc.next(0);
      

    });

  }

  hayModulos(){    
    return this.Hayfiltro( this.exportKnowbe4, 'swPurchases', -1 );
  }
  

  filtrarDatos(){

    this.swProcesando=true;

    this.exportKnowbe4.controls['desde'].setValue( this.fromDate.day.toString().padStart(2,'0')+'/'+this.fromDate.month.toString().padStart(2,'0')+'/'+this.fromDate.year.toString()   );
    this.exportKnowbe4.controls['hasta'].setValue( this.toDate.day.toString().padStart(2,'0')+'/'+this.toDate.month.toString().padStart(2,'0')+'/'+this.toDate.year.toString() );
    this.exportKnowbe4.controls['rango'].setValue(this.swPeriodoVig);
      
    this.exportKnowbe4.controls['desde'].disable();
    this.exportKnowbe4.controls['hasta'].disable();
    this.exportKnowbe4.controls['rango'].disable();

    this.exportKnowbe4.controls['swGerencias'].disable();
    this.exportKnowbe4.controls['swDepartamentos'].disable();
    this.exportKnowbe4.controls['swCargos'].disable();
    this.exportKnowbe4.controls['swGrupos'].disable();
    this.exportKnowbe4.controls['swPurchases'].disable();
    this.exportKnowbe4.controls['swSta_purchases'].disable();
    this.exportKnowbe4.controls['swPishing'].disable();
    this.exportKnowbe4.controls['swTraining'].disable();
    this.exportKnowbe4.controls['swStatus_pis'].disable();
    this.exportKnowbe4.controls['swStatus_cur'].disable();    

    this.exportKnowbe4.controls['swModSelModulo'].disable();    
    this.exportKnowbe4.controls['swModSelPishin'].disable();    
    this.exportKnowbe4.controls['swModSelTraini'].disable();    

    
    this.exportKnowbe4.controls['swtraining'].disable();
    this.exportKnowbe4.controls['swpishing'].disable();
    
    this.porSubproc = 0;

    this.knowbe4Service.porcenSubProc.next(0);

    const totalReg = this.data.length

    // Get and show filters

    const filGcia = this.Hayfiltro( this.exportKnowbe4, 'swGerencias', 0 );

    const filDpto = this.Hayfiltro( this.exportKnowbe4, 'swDepartamentos', 0 );
    const filCarg = this.Hayfiltro( this.exportKnowbe4, 'swCargos', 0 );

    const filGrup = this.Hayfiltro( this.exportKnowbe4, 'swGrupos', -1 );

    const filPish = this.Hayfiltro( this.exportKnowbe4, 'swPishing', -1 );
    const filTrai = this.Hayfiltro( this.exportKnowbe4, 'swTraining', -1 );
    const filModu = this.Hayfiltro( this.exportKnowbe4, 'swPurchases', -1 );

    const filStPi = this.HayfiltroString( this.exportKnowbe4, 'swStatus_pis', 'TODOS' );     
    const filStTr = this.HayfiltroString( this.exportKnowbe4, 'swStatus_cur', 'TODOS' ); 
    const filStMo = this.HayfiltroString( this.exportKnowbe4, 'swSta_purchases', 'TODOS' );

    const excMod = (filModu) ? this.exportKnowbe4.controls['swModSelModulo'].value == 1 : false;
    const excPis = (filPish) ? this.exportKnowbe4.controls['swModSelPishin'].value == 1 : false;
    const excTra = (filTrai) ? this.exportKnowbe4.controls['swModSelTraini'].value == 1 : false;    

    const filDPis = (this.exportKnowbe4.controls['swpishing'].value);
    const filDTra = (this.exportKnowbe4.controls['swtraining'].value);    

    const valGcia = this.getDesGcia();
    const valDpto = this.getDesDpto();
    const valCarg = this.getDesCargo();
    const valGrup = this.getDesGrupo();    

    const valPish = this.getDesPishing();
    const valTrai = this.getDesTraining();
    const valModu = this.getDesModulo();

    const valStPi = this.getDesStaPishin();
    const valStTr = this.getDesStaTrain();
    const valStMo = this.getDesStaModulo();    
    

    const valDPis = (this.exportKnowbe4.controls['swpishing'].value);
    const valDTra = (this.exportKnowbe4.controls['swtraining'].value);     
    
    
    const valDesde = moment( this.exportKnowbe4.controls['desde'].value,'DD/MM/YYYY').toDate()
    const valHasta = moment( this.exportKnowbe4.controls['hasta'].value,'DD/MM/YYYY').toDate()

    this.swFiltrando = true;

    this.dataF = [];

    this.dataExcluidosPishing = [];
    this.dataExcluidosTraining = [];
    this.dataExcluidosModulos = [];

    this.knowbe4Service.porcenSubProc.next( 0 );        

    this.dataF = this.data.filter( (reg,i) => {       

      this.knowbe4Service.porcenSubProc.next( ((i+1)*100) / totalReg );

      let selGcia = true;
      let selDpto = true;
      let selCarg = true;
      let selGrup = true;
      let selModu = true;
      let selPish = true;
      let selTrai = true;
      let selStaT = true;
      let selStaP = true;
      let selStaM = true;
      let selFpis = true;
      let selFtra = true;
      
      if( filGcia ) { selGcia = ( valGcia.indexOf( String(reg.gcia).toUpperCase() ) >= 0); }

      if( filDpto ) { selDpto = ( valDpto.indexOf( String(reg.dpto).toUpperCase() ) >= 0); }

      if( filCarg ) { selCarg = ( valCarg.indexOf( String(reg.cargo).toUpperCase() ) >= 0); }

      if ( filGrup ) {

        const gus:[] = reg.grupos.filter( g =>{ 
          return  (valGrup.indexOf( String(g.name).toUpperCase() ) >= 0) 
        } )

        selGrup = ( gus.length>0 )

      }

      if ( filPish || filStPi || filDPis ) {

        const pis:[] = reg.pishing.filter( r =>{ 

          const creada = new Date( r.recibido );
          
          return ( 
                    (( filPish ) ? (valPish.indexOf( String(r.pishing).toUpperCase() ) >= 0 ) : true) &&  
                    (( filStPi ) ? (valStPi.indexOf( String(r.status).toUpperCase() ) >= 0) : true ) &&
                    (( filDPis ) ? moment(creada).isAfter(valDesde) : true ) &&
                    (( filDPis ) ? moment(creada).isBefore(valHasta) : true )      )        

        })

        selFpis = selStaP = selPish = ( pis.length>0 );

      }

      if ( filTrai || filStTr || filDTra ) {        

        const trai:[] = reg.training.filter( r =>{ 

          
          const inicio = r.inicio ;             
          
          return ( 
                    (( filTrai ) ? ( valTrai.indexOf( String(r.nombre_camp).toUpperCase() )>=0) : true) &&  
                    (( filStTr ) ? ( valStTr.indexOf( String(r.statust).toUpperCase() )>=0) : true ) &&
                    (( filDTra ) ? moment(inicio).isAfter(valDesde) : true ) &&
                    (( filDTra ) ? moment(inicio).isBefore(valHasta) : true ) 
                    
                  )        

        })

        selFtra = selStaT = selTrai = ( trai.length>0 );
      
      } 

      if ( filModu || filStMo ) {        

        const trai:[] = reg.training.filter( r =>{ 

          
          const inicio = r.inicio ;             
          
          return (                    
                    (( filModu ) ? (valModu.indexOf( String(r.modulo).toUpperCase() )>=0) : true ) &&     
                    (( filStMo ) ? (valStMo.indexOf( String(r.status).toUpperCase() )>=0) : true ) 
                  )        

        })

        selStaM = selModu = ( trai.length>0 );
      
      } 
      
      if  ( excTra ||  excMod  || excPis )  {

        const esUsuario = selGcia && selDpto && selCarg && selGrup;

        if ( esUsuario ) {
          
          let fueExclMod = false;
          let fueExclTrai = false;
          let fueExclPish = false; 
          
          let esSel = false;
          
          let BselStaM = true;
          let BselStaP = true;
          let BselStaT = true;

          if ( excTra && filTrai ) {

            fueExclTrai = !selTrai;                                   

            return fueExclTrai &&  selModu && selPish  && selStaM && selStaP;            

          }

          if ( excMod && filModu ) {

            fueExclMod = !selModu; 

            return fueExclMod &&  selPish  && selTrai && selStaP && selStaT;            

          }

          if ( excPis  && filPish ) {
            fueExclPish = !selPish;                       
            return fueExclPish && selModu && selTrai && selStaM && selStaT;            
          }

          return false;

        } else {
          return false;
        }
        
      } else {

        return ( selGcia && selDpto && selCarg && selGrup && selModu && selPish  && selTrai && selStaM && selStaP && selStaT );

      }
      
    })

    this.knowbe4Service.porcenSubProc.next( 100 );

    this.dataB = this.dataF;

    this.swFiltrando=false;
    this.swFiltrado=true;

    this.totRegFiltrados = this.dataF.length;
    this.exportKnowbe4.controls['buscar'].setValue("");

    this.swPaso=3;

    this.swProcesando=false;

    this.refreshPage();
  }

  defaultFilter() {


    this.exportKnowbe4.controls['recurso'].setValue(-1);
    this.exportKnowbe4.controls['est_recurso'].setValue("TODOS");

    
    this.exportKnowbe4.controls['swGrupos'].setValue([-1]);
    this.exportKnowbe4.controls['swPurchases'].setValue([-1]);
    this.exportKnowbe4.controls['swCargos'].setValue([0]);
    this.exportKnowbe4.controls['swGerencias'].setValue([0]);
    this.exportKnowbe4.controls['swDepartamentos'].setValue([0]);
    this.exportKnowbe4.controls['swSta_purchases'].setValue(["TODOS"]);
    this.exportKnowbe4.controls['swStatus_cur'].setValue(["TODOS"]);
    this.exportKnowbe4.controls['swStatus_pis'].setValue(["TODOS"]);
    this.exportKnowbe4.controls['swPishing'].setValue([-1]);
    this.exportKnowbe4.controls['swTraining'].setValue([-1]);
    
    this.exportKnowbe4.controls['idpishing'].setValue(-1);
    this.exportKnowbe4.controls['swpishing'].setValue(false);
    this.exportKnowbe4.controls['est_pish'].setValue("TODOS");
    this.exportKnowbe4.controls['idtraining'].setValue(-1);
    this.exportKnowbe4.controls['swtraining'].setValue(false);
    this.exportKnowbe4.controls['est_trai'].setValue("TODOS");    

    this.exportKnowbe4.controls['swModSelGer'].setValue(0);   
    
    this.exportKnowbe4.controls['swModSelModulo'].setValue(0);    
    this.exportKnowbe4.controls['swModSelPishin'].setValue(0);    
    this.exportKnowbe4.controls['swModSelTraini'].setValue(0);    

    this.exportKnowbe4.controls['buscar'].setValue('');

  }

  limpiarFiltro(){

    this.swFiltrado=false;

    this.exportKnowbe4.controls['desde'].enable();
    this.exportKnowbe4.controls['hasta'].enable();
    this.exportKnowbe4.controls['rango'].enable();

    this.exportKnowbe4.controls['swtraining'].enable();
    this.exportKnowbe4.controls['swpishing'].enable();
    
    this.exportKnowbe4.controls['swGerencias'].enable();
    this.exportKnowbe4.controls['swDepartamentos'].enable();
    this.exportKnowbe4.controls['swCargos'].enable();
    this.exportKnowbe4.controls['swGrupos'].enable();
    this.exportKnowbe4.controls['swPurchases'].enable();
    this.exportKnowbe4.controls['swSta_purchases'].enable();
    this.exportKnowbe4.controls['swPishing'].enable();
    this.exportKnowbe4.controls['swTraining'].enable();
    this.exportKnowbe4.controls['swStatus_pis'].enable();
    this.exportKnowbe4.controls['swStatus_cur'].enable();    

    this.exportKnowbe4.controls['swModSelModulo'].enable();    
    this.exportKnowbe4.controls['swModSelPishin'].enable();    
    this.exportKnowbe4.controls['swModSelTraini'].enable();    

    this.knowbe4Service.porcenSubProc.next(0);
    this.dataB = [];
    this.dataF = [];

    this.totRegFiltrados = this.dataF.length;

    this.swPaso=3;

    this.defaultFilter();

    this.refreshPage();
    this.swPaso=2;
  }

  editarFiltro(){

    this.swFiltrado=false;

    this.exportKnowbe4.controls['desde'].enable();
    this.exportKnowbe4.controls['hasta'].enable();
    this.exportKnowbe4.controls['rango'].enable();

    this.exportKnowbe4.controls['swtraining'].enable();
    this.exportKnowbe4.controls['swpishing'].enable();
    
    this.exportKnowbe4.controls['swGerencias'].enable();
    this.exportKnowbe4.controls['swDepartamentos'].enable();
    this.exportKnowbe4.controls['swCargos'].enable();
    this.exportKnowbe4.controls['swGrupos'].enable();
    this.exportKnowbe4.controls['swPurchases'].enable();
    this.exportKnowbe4.controls['swSta_purchases'].enable();
    this.exportKnowbe4.controls['swPishing'].enable();
    this.exportKnowbe4.controls['swTraining'].enable();
    this.exportKnowbe4.controls['swStatus_pis'].enable();
    this.exportKnowbe4.controls['swStatus_cur'].enable();

    this.exportKnowbe4.controls['swModSelModulo'].enable();    
    this.exportKnowbe4.controls['swModSelPishin'].enable();    
    this.exportKnowbe4.controls['swModSelTraini'].enable();    


    this.knowbe4Service.porcenSubProc.next(0);
    this.dataB = [];
    this.dataF = [];

    this.totRegFiltrados = this.dataF.length;

    this.swPaso=3;    

    this.refreshPage();
    this.swPaso=2;
  }

  async exportarData( ){
 

    const workBook : XLSX.WorkBook = XLSX.utils.book_new();

    const valGcia = this.getDesGcia();
    const valDpto = this.getDesDpto();
    const valCarg = this.getDesCargo();
    const valGrup = this.getDesGrupo();

    const valPish = this.getDesPishing();
    const valTrai = this.getDesTraining();
    const valModu = this.getDesModulo();

    const valStPi = this.getDesStaPishin();
    const valStTr = this.getDesStaTrain();
    const valStMo = this.getDesStaModulo();

    const unixDate=moment( '01/01/1970','DD/MM/YYYY').toDate();    

    let dataExportar = [];
    this.xlsUsuarios = [];
    this.xlsUserGroup = [];
    this.xlsUserPish =[];
    this.xlsResTraining =[];
    this.xlsPishing=[];
    this.xlsRecursos =[];
    this.xlsTraining =[];
    this.xlsGrupos = [];
        

    await Swal.fire({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      width: '38%',
      icon: 'success',
      timerProgressBar: true,
      timer: 1000,
      title: 'Se esta exportado todos los registros'
    })      

    this.usuarios.forEach( user => {        

      dataExportar.push(

        {
          id : user.id,
          nombre : String(user.first_name+' '+user.last_name).toUpperCase(),
          cargo : String(user.job_title).toUpperCase(),
          dpto : String(user.department).toUpperCase(),
          gcia : String(user.division).toUpperCase(),
          email : user.email,
          grupos : this.gruposUsuario( user.groups ),
          pishing : this.resultadosPishing( user.id ),
          training : this.resultadosTraining( user.id )
        }

      );          

      this.xlsUsuarios.push(
        {
          id : user.id,
          email :         (user.email).toString().toLowerCase(),
          nombre :        (user.first_name+' '+user.last_name).toString().toUpperCase(),
          cargo :         (user.job_title).toString().toUpperCase(),
          estatus :       (user.status).toString().toUpperCase(),
          departamento :  (user.department).toString().toUpperCase(),
          gerencia :      ( ( user.division == null ? '' : user.division)).toString().toUpperCase(),
          supervisor :    ( ( user.manager_name == null ? '': user.manager_name)).toString().toUpperCase(),
          emailSuper :    ( ( user.manager_emai == null ? '': user.manager_emai) ).toString().toLowerCase()
        }
      )

    })    

    dataExportar.forEach( user =>{     

      user.grupos.forEach( uGrp =>{

        this.xlsUserGroup.push( {
          id : user.id,
          nombre : String(user.nombre).toUpperCase(),
          id_grupo : uGrp.id,
          grupo : String(uGrp.name).toString().toUpperCase()
        })
      })

      user.pishing.forEach( uPish =>{        
        this.xlsUserPish.push(
          {
            id : user.id,
            nombre : this.getNameUser( user.id).toString().toUpperCase(), 
            pst_id : uPish.pst_id,
            pishing_id : uPish.pishing_id, 
            pishing : String(uPish.pishing).toUpperCase(),
            template : String(uPish.template).toUpperCase(),
            entregado : (uPish.entregado!= null) ? new Date(uPish.entregado) : null,            
            abierto : (uPish.abierto!= null) ? new Date(uPish.abierto) : null,
            clicked : (uPish.clicked!= null) ? new Date(uPish.clicked) : null,
            replied : (uPish.replied!= null) ? new Date(uPish.replied) : null,
            attachmet : (uPish.attachmet!= null) ? new Date(uPish.attachmet) : null,
            macroe : (uPish.macroe!= null) ? new Date(uPish.macroe) : null,
            datae : (uPish.dataenter!= null) ? new Date(uPish.dataenter) : null,
            reported : (uPish.reported!= null) ? new Date(uPish.reported) : null,
            status : String(uPish.status).toUpperCase()            
          }
        )
      })

      user.training.forEach( uTrain =>{ 
        
        this.xlsResTraining.push(
          {
            id : user.id,
            nombre : this.getNameUser( user.id).toString().toUpperCase(), 
            enrollment_id : uTrain.enrollment_id,
            enrollment_date :  ( moment(this.getEnrollmentDate(uTrain.enrollment_id)).isAfter(unixDate) ) ? new Date(this.getEnrollmentDate(uTrain.enrollment_id)) : null,               
            nombre_camp :uTrain.nombre_camp,
            campana_id : this.getCampID( uTrain.nombre_camp ),
            completed : ( moment(uTrain.completed).isAfter(unixDate) ) ? new Date(uTrain.completed) : null,
            modulo :uTrain.modulo,
            inicio : ( moment(uTrain.inicio).isAfter(unixDate) ) ? new Date(uTrain.inicio) : null,
            status :String(uTrain.status).toUpperCase(),        
            tiempo :uTrain.tiempo
          }
        )

      })

    })

    this.pishing.forEach( p =>{
      // console.llog('Pishing ==>',p);
      this.xlsPishing.push({
        id: p.campaign_id,
        nombre: String(p.name).toString().toUpperCase(),
        creada : new Date( p.create_date) ,
        grupos : p.groups.length,          
        frecuencia : String(p.frequency).toUpperCase(),
        duracion : String( p.send_duration).toUpperCase(),
        estatus : String( p.status ).toUpperCase(),
        seguimiento : String( p.track_duration ).toUpperCase()

      })
    })

    this.training.forEach( t =>{
      this.xlsTraining.push({
        id: t.campaign_id,
        name: String(t.name.toString().toUpperCase()),
        inicio: new Date(t.start_date),
        fin: new Date(t.end_date),
        duracion: String(t.duration_type).toUpperCase(),
        auto_enroll: (t.auto_enroll) ? 'Si':'No',
        status: String(t.status).toString().toUpperCase(),
        // objetos : t.content.legnth,
        completado : t.completion_percentage,
      })
    }) 

    
    this.purchases.forEach( t =>{
      this.xlsRecursos.push({
        id: t.store_purchase_id,
        name: String(t.name).toString().toUpperCase(),
        inicio: new Date( t.purchase_date ),
        duracion: t.duration,          
        tipo: String(t.type).toString().toUpperCase()
      })
    })
    
    this.grupos.forEach( g => {
      this.xlsGrupos.push( { 
        id : g.id,
        name : String(g.name).toString().toUpperCase(),
        miembros : g.member_count,
        estatus : String(g.status).toString().toUpperCase()
      } )
    })
    

    // 2.- Usuarios

    const usr: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.xlsUsuarios);    
      
    usr['!cols'] = [
      {wch:10},
      {wch:30},
      {wch:40},
      {wch:60},
      {wch:10},
      {wch:80},
      {wch:80},
      {wch:30},
      {wch:25},
      {wch:30}
    ];

    XLSX.utils.book_append_sheet( workBook, usr,    'Usuarios');

    // 3.- Grupos
        
    const grp: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.xlsGrupos);    
    
    grp['!cols'] = [
        {wch:10},
        {wch:50},      
        {wch:10}      
    ];

    XLSX.utils.book_append_sheet( workBook, grp,    'Grupos');

    // 4.- Usuarios por grupo

    const grpUsr: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.xlsUserGroup);
    
    grpUsr['!cols'] = [
        {wch:10},
        {wch:50},      
        {wch:10},      
        {wch:50}      
    ];

    XLSX.utils.book_append_sheet( workBook, grpUsr, 'User-Grupos');

    // 5.- Recursos de training
    
    const recT: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.xlsRecursos);    
    
    recT['!cols'] = [
        {wch:10},
        {wch:80},      
        {wch:12},      
        {wch:8},      
        {wch:20}      
    ];

    XLSX.utils.book_append_sheet( workBook, recT,   'Recursos-training');
    
    // 6.- Trainings

    const train: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.xlsTraining);    

    train ['!cols'] = [
      {wch:10},
      {wch:60},      
      {wch:12},      
      {wch:12},      
      {wch:20},
      {wch:10},
      {wch:15},
      // {wch:10},
      {wch:10}
    ];

    XLSX.utils.book_append_sheet( workBook, train,   'Trainings');

    // 7.- Usuarios por training

    const trainU: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.xlsResTraining);    

    trainU ['!cols'] = [
      {wch:10},
      {wch:30},      
      {wch:10},      
      {wch:12},      
      {wch:60},      
      {wch:10},      
      {wch:12},
      {wch:60},
      {wch:12},
      {wch:12},
      {wch:10}
    ];

    XLSX.utils.book_append_sheet( workBook, trainU,   'Trainings-Usuarios');


    // 8.- Pishing

    const pishi: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.xlsPishing);    

    pishi ['!cols'] = [
      {wch:10},
      {wch:40},      
      {wch:12},      
      {wch:12},      
      {wch:20},
      {wch:20},
      {wch:12},
      {wch:12}
    ];

    XLSX.utils.book_append_sheet( workBook, pishi,   'Pishing');

    // 9.- Usuarios por pishing    

    const usrP: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.xlsUserPish);    

    usrP['!cols'] = [
      {wch:10},
      {wch:30},
      {wch:10},
      {wch:10},
      {wch:30},
      {wch:30},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12}
    ];

    XLSX.utils.book_append_sheet( workBook, usrP,   'Pishin-Usuarios');    

    // Escribir hoja de calculo
    XLSX.writeFile( workBook , this.fileName);
    

  }

  async exportarFiltro( ){
 

    const workBook : XLSX.WorkBook = XLSX.utils.book_new();

    const valGcia = this.getDesGcia();
    const valDpto = this.getDesDpto();
    const valCarg = this.getDesCargo();
    const valGrup = this.getDesGrupo();

    const valPish = this.getDesPishing();
    const valTrai = this.getDesTraining();
    const valModu = this.getDesModulo();

    const valStPi = this.getDesStaPishin();
    const valStTr = this.getDesStaTrain();
    const valStMo = this.getDesStaModulo();

    const valBusc = String(this.exportKnowbe4.controls['buscar'].value).toLowerCase();

    const valDesde = moment( this.exportKnowbe4.controls['desde'].value,'DD/MM/YYYY').toDate()
    const valHasta = moment( this.exportKnowbe4.controls['hasta'].value,'DD/MM/YYYY').toDate()
    

    const filGrup = this.Hayfiltro( this.exportKnowbe4, 'swGrupos', -1 );

    const filPish = this.Hayfiltro( this.exportKnowbe4, 'swPishing', -1 );
    const filTrai = this.Hayfiltro( this.exportKnowbe4, 'swTraining', -1 );
    const filModu = this.Hayfiltro( this.exportKnowbe4, 'swPurchases', -1 );

    const filStPi = this.HayfiltroString( this.exportKnowbe4, 'swStatus_pis', 'TODOS' );     
    const filStTr = this.HayfiltroString( this.exportKnowbe4, 'swStatus_cur', 'TODOS' ); 
    const filStMo = this.HayfiltroString( this.exportKnowbe4, 'swSta_purchases', 'TODOS' );

    const filDPis = (this.exportKnowbe4.controls['swpishing'].value);
    const filDTra = (this.exportKnowbe4.controls['swtraining'].value);

    await Swal.fire({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      width: '38%',
      icon: 'success',
      timerProgressBar: true,
      timer: 1000,
      title: 'Se esta exportado todos los registros seleccionado con el filtro'
    }) 

    this.xlsFiltros = [
      
      {
        parametro : 'Gerencia',
        valor : valGcia
      },
      {
        parametro : 'Departamento',
        valor : valDpto
      },
      {
        parametro : 'Cargo',
        valor : valCarg
      },
      {
        parametro : 'Grupo',
        valor : valGrup
      },
      {
        parametro : 'Pishing',
        valor : valPish
      },
      {
        parametro : 'Training',
        valor : valTrai
      },
      {
        parametro : 'Modulo',
        valor : valModu
      },
      {
        parametro : 'Estatus registros',
        valor : 'TODOS'
      },
      {
        parametro : 'Estatus Curso',
        valor : valStTr
      },
      {
        parametro : 'Estatus Modulo',
        valor : valStMo
      },
      {
        parametro : 'Estatus Pishing',
        valor : valStPi
      },
      {
        parametro : 'Palabras',
        valor : this.exportKnowbe4.controls['buscar'].value
      }
    ]

    if (this.exportKnowbe4.controls['swpishing'].value || this.exportKnowbe4.controls['swtraining'].value ) {
      this.xlsFiltros.push( 
        {
          parametro : 'Hasta',
          valor : this.exportKnowbe4.controls['hasta'].value
        }

      );
      this.xlsFiltros.push(
        {
          parametro : 'Desde',
          valor : this.exportKnowbe4.controls['desde'].value
        }
        
      );
    }

    let dataExportar = [];

    this.xlsUsuarios = [];
    this.xlsUserGroup = [];
    this.xlsUserPish =[];
    this.xlsResTraining =[];
    this.xlsPishing=[];
    this.xlsRecursos =[];
    this.xlsTraining =[];
    this.xlsGrupos = [];

    const text:string = String(this.exportKnowbe4.controls['buscar'].value).toString().toLowerCase();

    const text1 = text.toLowerCase();

    let termBusqTmp = text1.split(/\s{1,10}/); 


    dataExportar = this.dataF
    .filter( reg => { 
      
      const filtrar:boolean = ( valBusc.length > 0 );        

      if ( filtrar ) {

        const cadenaDestino = reg.nombre.toLowerCase()+' '+reg.cargo.toLowerCase()+' '+reg.gcia.toLowerCase()+' '+reg.dpto.toLowerCase()+' '+reg.email.toLowerCase()

        let loTiene = false;          

        let ele2 = 0;

        let cnt = 0;

        while (ele2< termBusqTmp.length ) {

          let e= termBusqTmp[ele2];

          loTiene = cadenaDestino.includes(e.toLowerCase());
          if ( loTiene ) {
            cnt ++
          }

          ele2 ++
          
        }          

        return ( cnt == termBusqTmp.length )
      
      } else {

        return true;
        
      } 
      
    }).map( (dato,i) =>{      

      let datTemp: any = {        
        id : dato.id,
        nombre : dato.nombre,
        cargo : dato.cargo,
        gcia : dato.gcia,
        dpto : dato.dpto,
        email : dato.email
      };

      if ( this.swVinculada && filGrup ) {

        const gus:[] = dato.grupos.filter( g =>{ 
          return  (valGrup.indexOf( String(g.name).toUpperCase() ) >= 0) 
        } )

        if ( gus.length>0 ) datTemp.grupos = gus; else datTemp.grupos = [];

      } else {
        datTemp.grupos = dato.grupos;
      }

      if ( this.swVinculada && (filPish || filStPi || filDPis )) {

        const pis:[] = dato.pishing.filter( r =>{ 

          const creada = new Date( r.recibido );
                    
          return ( 
                      (( filPish ) ? (valPish.indexOf( String(r.pishing).toUpperCase() ) >= 0 ) : true) &&  
                      (( filStPi ) ? (valStPi.indexOf( String(r.status).toUpperCase() ) >= 0) : true ) &&
                      (( filDPis ) ? moment(creada).isAfter(valDesde) : true ) &&
                      (( filDPis ) ? moment(creada).isBefore(valHasta) : true )      ) 

        })

        if ( pis.length>0 ) datTemp.pishing = pis; else datTemp.pishing = [];
        

      } else {
        datTemp.pishing = dato.pishing;
      }

      if ( this.swVinculada && (filTrai || filStTr || filDTra || filModu || filStMo )) {

        const trai:[] = dato.training.filter( r =>{ 

          const inicio = r.inicio ;     
                  
          return ( 
                    (( filTrai ) ? ( valTrai.indexOf( String(r.nombre_camp).toUpperCase() )>=0) : true) &&  
                    (( filStTr ) ? ( valStTr.indexOf( String(r.statust).toUpperCase() )>=0) : true ) &&
                    (( filDTra ) ? moment(inicio).isAfter(valDesde) : true ) &&
                    (( filDTra ) ? moment(inicio).isBefore(valHasta) : true ) &&     
                    (( filModu ) ? (valModu.indexOf( String(r.modulo).toUpperCase() )>=0) : true ) &&     
                    (( filStMo ) ? (valStMo.indexOf( String(r.status).toUpperCase() )>=0) : true ) 
                  ) 

        })

        if ( trai.length>0 ) datTemp.training = trai; else datTemp.training = [];

      } else {
        datTemp.training = dato.training;
      }

      return datTemp

    })

    let pos = 0;

    let xlsFiltrados = [];

    let lineas = 0;

    while ( pos< dataExportar.length ) {

      let reg = dataExportar[pos];

      let training:any[] = reg.training;

      let pishing:any[]  = reg.pishing;

      let totalRows = ( training.length >= pishing.length ) ? training.length : pishing.length;      

      let cntRows = 0;

      while (cntRows< (totalRows)) {

        lineas ++

        let dataXls: any = { idL : lineas };        

        dataXls.id = reg.id;
        dataXls.nombre = reg.nombre;
        dataXls.email = reg.email;
        dataXls.cargo = reg.cargo;
        dataXls.gcia = reg.gcia;
        dataXls.dpto = reg.dpto;          

        if (cntRows < (pishing.length)) {

          const pish:any = pishing[cntRows];

          dataXls.pst_id = pish.pst_id;
          dataXls.pishing_id = pish.pishing_id;          
          dataXls.pishing = (pish.pishing as string).toUpperCase();
          dataXls.template = (pish.template as string).toUpperCase();
          dataXls.recibido = (pish.recibido!= null) ? new Date(pish.recibido) : null;
          dataXls.abierto = (pish.abierto!= null) ? new Date(pish.abierto) : null;
          dataXls.clicked = (pish.clicked!= null) ? new Date(pish.clicked) : null;
          dataXls.replied = (pish.repliead!= null) ? new Date(pish.repliead) : null;
          dataXls.attachmet = (pish.attachmet!= null) ? new Date(pish.attachmet) : null;
          dataXls.macroe = (pish.macroe!= null) ? new Date(pish.macroe) : null;

          dataXls.dataenter = (pish.data_enter!= null) ? new Date(pish.data_enter) : null;

          dataXls.reported = (pish.reported!= null) ? new Date(pish.reported) : null;
          dataXls.status = (pish.status as string).toUpperCase();

        } else {

          dataXls.pst_id = '';
          dataXls.pishing = '';
          dataXls.template = '';
          dataXls.recibido = '';
          dataXls.abierto = '';
          dataXls.clicked = '';
          dataXls.replied = '';
          dataXls.attachmet = '';
          dataXls.macroe = '';
          dataXls.dataenter = '';
          dataXls.reported = '';
          dataXls.status = '';
          
        }

        if (cntRows < (training.length)) {

          const trai:any = training[cntRows];          

          dataXls.enrollment_id = trai.enrollment_id;          
          dataXls.campana_id = this.getCampID( trai.nombre_camp );
          dataXls.nombre_camp = trai.nombre_camp;
          dataXls.stat_camp = trai.statust;          
          dataXls.inscripto = (trai.inscripto!= null) ? new Date(trai.inscripto) : null;
          dataXls.enrollment_date = ( moment(this.getEnrollmentDate(trai.enrollment_id))!= null ) ? new Date(this.getEnrollmentDate(trai.enrollment_id)) : null;
          dataXls.modulo_id = this.getModuleID( trai.modulo );
          dataXls.modulo = trai.modulo;
          dataXls.inicio = (trai.inicio!= null) ? new Date(trai.inicio) : null;
          dataXls.stat_mod = trai.status;
          dataXls.tipo_mod = trai.tipo;
          dataXls.tiempo = trai.tiempo;

        } else {

          dataXls.enrollment_id = '';
          dataXls.nombre_camp = '';
          dataXls.stat_camp = '';
          dataXls.inscripto = '';
          dataXls.modulo = '';
          dataXls.inicio = '';
          dataXls.stat_mod = '';
          dataXls.tipo_mod = '';
          dataXls.tiempo = '';

        }

        xlsFiltrados.push( dataXls );
        cntRows ++;

      }

      pos ++;

    }
    

    // 1 .- Filtros y Configuracion

    const cnf: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.xlsFiltros);    

    cnf['!cols'] = [
      {wch:15},
      {wch:50}      
    ];

    XLSX.utils.book_append_sheet( workBook, cnf, 'Configuracion');

    // 2.- usuarios-pishing-training

    const filtrados: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsFiltrados);    

    
      
      filtrados['!cols'] = [
          {wch:4},  // 
          {wch:10}, //
          {wch:40}, //
          {wch:30}, //
          {wch:60}, //
          {wch:60}, //
          {wch:60}, //
          {wch:10}, //
          {wch:10}, //
          {wch:30}, //
          {wch:30}, //
          {wch:12}, //
          {wch:12}, //
          {wch:12}, //
          {wch:12}, // 
          {wch:12}, //
          {wch:12}, //
          {wch:12}, //
          {wch:12}, //
          {wch:10}, //
          {wch:12}, //
          {wch:12}, //
          {wch:60}, //
          {wch:15}, //
          {wch:12}, //
          {wch:12}, //
          {wch:12}, //
          {wch:60}, //
          {wch:12}, //
          {wch:15}, //
          {wch:18}, //
          {wch:12}
        ];

    XLSX.utils.book_append_sheet( workBook, filtrados, 'Filtrados');

    // Escribir hoja de calculo
    XLSX.writeFile( workBook , this.fileNameFiltro);
    


  }

  cancelar() {    
    
  }  

  rerender(): void {
    
  }

  gruposUsuario( grupos:any[]) {

    let gruposRetornar = []

    grupos.forEach( g =>{

      if (g != null) {

        const sg = this.grupos.filter( e => { return g == e.id });

        if ( sg.length >0 ) {
          gruposRetornar.push( {

            id : sg[0].id,
            name : sg[0].name

          });
        }
      }
    })

    return gruposRetornar
  }

  resultadosPishing( id:number ) {

    let retorResultados = [];

    const resultados = this.allResults.filter( e =>{ return e.user.id == id });

    resultados.forEach( r => {      

      retorResultados.push( {

        pst_id : r.pst_id,
        pishing : this.getNamePishing( r.pst_id ),        
        pishing_id : this.getIdPishing( r.pst_id ),        
        creada : this.getCreatedPishing( r.pst_id ),        
        status : this.getStatePishing( r.pst_id ),        
        entregado : r.delivered_at,
        recibido : r.delivered_at,
        abierto : r.opened_at,
        clicked : r.clicked_at,
        data_enter: r.data_entered_at,
        replied : r.replied_at,
        attachmet : r.attachment_opened_at, 
        macroe : r.macro_enabled_at,
        reported : r.reported_at,
        template : r.template.name


      })
    })

    return retorResultados

  }

  resultadosTraining( id:number ) {

    let retorResultados = [];

    const resultados = this.trainingUsers.filter( t =>{ return t.user.id == id });

    resultados.forEach( r => {      

      retorResultados.push( {

        enrollment_id : r.enrollment_id,        
        nombre_camp : String( r.campaign_name ).toUpperCase(),
        // inscripto : (r.completion_date!=null) ? new Date( r.completion_date ): null,
        completed : (r.completion_date!=null) ? new Date( r.completion_date ): null,
        modulo : String( r.module_name ).toUpperCase(),
        inicio : (r.start_date!=null) ? new Date(r.start_date) : null,
        tipo : this.getTipoModulo( r.module_name ),
        statust : this.getStatusTrainingRef( r.campaign_name ),
        status : String( r.status ).toUpperCase(),        
        tiempo : r.time_spent

      })
    })

    // console.log( 'rr=>',retorResultados[0]);

    return retorResultados

  }

  getNamePishing( pst_id: number ) {

    const pp:any[] = this.pishing.filter( p => { 
      const ps:any[] = p.psts.filter( pst => { return pst.pst_id == pst_id } )
      return ( ps.length > 0 )
    })     
    return (pp.length>0) ? pp[0].name : 'No encontrada'
  
  }

  getCampID( camp_name: string ) {    
    const pp:any[] = this.training.filter( p => { return p.name.toUpperCase() == camp_name.toUpperCase() });
    return (pp.length>0) ? pp[0].campaign_id : 'No encontrada'
  
  }

  getModuleID( module_name: string ) {    
    const pp:any[] = this.purchases.filter( p => {       
      return p.name.toUpperCase().replace(/\s/g, '') == module_name.toUpperCase().replace(/\s/g, '') 
    });       
    if (pp.length==0) { console.log('module_name =>',module_name.replace(/\s/g, '')) }
    return (pp.length>0) ? pp[0].store_purchase_id : 'No encontrada'  ;
  }

  getIdPishing( pst_id: number ) {

    const pp:any[] = this.pishing.filter( p => { 
      const ps:any[] = p.psts.filter( pst => { return pst.pst_id == pst_id } )
      return ( ps.length > 0 )
    }) 

    return (pp.length>0) ? pp[0].campaign_id : 'No encontrada'
  }

  getStatePishing( pst_id: number ) {

    const pp:any[] = this.pishing.filter( p => { 
      const ps:any[] = p.psts.filter( pst => { return pst.pst_id == pst_id } )
      return ( ps.length > 0 )
    }) 

    return (pp.length>0) ? pp[0].status : 'No encontrada'
  }

  getCreatedPishing( pst_id: number ) {

    const pp:any[] = this.pishing.filter( p => { 
      const ps:any[] = p.psts.filter( pst => { return pst.pst_id == pst_id } )
      return ( ps.length > 0 )
    }) 

    return (pp.length>0) ? pp[0].create_date : 'No encontrada'
  }

  getTipoModulo( n: string ) {

    const pp:any[] = this.purchases.filter( p => {           

      return ( String(p.name).toUpperCase() == n.toUpperCase() );

    }) 

    return (pp.length>0) ? pp[0].type.toUpperCase() : 'No encontrada'
  }

  getNameUser( id ) {

    const userN:any[] = this.usuarios.filter( u => { return  u.id == id  })

    return (userN.length>0) ? (userN[0].first_name+' '+userN[0].last_name).toString().toUpperCase() : 'No encontrada'

  }

  irSincronizar() {
    this.setTab(2)
  }

  setTab( id ) {
    this.tabSet = id;
    
  }

  swpishingChange() {
    if ( !this.swFiltrando && !this.swFiltrado ) this.exportKnowbe4.controls['swpishing'].setValue( !this.exportKnowbe4.controls['swpishing'].value );
  }

  swtrainingChange() {
    if ( !this.swFiltrando && !this.swFiltrado ) this.exportKnowbe4.controls['swtraining'].setValue( !this.exportKnowbe4.controls['swtraining'].value );
  }

  refreshPage() { 
    
    const valGrup = this.getDesGrupo();

    const valPish = this.getDesPishing();
    const valTrai = this.getDesTraining();
    const valModu = this.getDesModulo();

    const valStPi = this.getDesStaPishin();
    const valStTr = this.getDesStaTrain();
    const valStMo = this.getDesStaModulo();

    const valDPis = (this.exportKnowbe4.controls['swpishing'].value);
    const valDTra = (this.exportKnowbe4.controls['swtraining'].value);

    const valDesde = moment( this.exportKnowbe4.controls['desde'].value,'DD/MM/YYYY').toDate()
    const valHasta = moment( this.exportKnowbe4.controls['hasta'].value,'DD/MM/YYYY').toDate()

    const filGcia = this.Hayfiltro( this.exportKnowbe4, 'swGerencias', 0 );

    const filDpto = this.Hayfiltro( this.exportKnowbe4, 'swDepartamentos', 0 );
    const filCarg = this.Hayfiltro( this.exportKnowbe4, 'swCargos', 0 );

    const filGrup = this.Hayfiltro( this.exportKnowbe4, 'swGrupos', -1 );

    const filPish = this.Hayfiltro( this.exportKnowbe4, 'swPishing', -1 );
    const filTrai = this.Hayfiltro( this.exportKnowbe4, 'swTraining', -1 );
    const filModu = this.Hayfiltro( this.exportKnowbe4, 'swPurchases', -1 );

    const filStPi = this.HayfiltroString( this.exportKnowbe4, 'swStatus_pis', 'TODOS' );     
    const filStTr = this.HayfiltroString( this.exportKnowbe4, 'swStatus_cur', 'TODOS' ); 
    const filStMo = this.HayfiltroString( this.exportKnowbe4, 'swSta_purchases', 'TODOS' );

    const filDPis = (this.exportKnowbe4.controls['swpishing'].value);
    const filDTra = (this.exportKnowbe4.controls['swtraining'].value);    

    this.collectionSize = this.dataB.length;
    this.infoTable = "Encontrados : "+this.encontrados;
    this.pagePwSize = this.exportKnowbe4.controls["pagePwSize"].value;   
    const text:string = String(this.exportKnowbe4.controls['buscar'].value).toString().toLowerCase();

    const text1 = text.toLowerCase();

    let termBusqTmp = text1.split(/\s{1,10}/);  

    this.dataPw = this.dataF
      .filter( reg => { 
        
        const filtrar:boolean = ( text.length > 0 );        

        if ( filtrar ) {

          const cadenaDestino = reg.nombre.toLowerCase()+' '+reg.cargo.toLowerCase()+' '+reg.gcia.toLowerCase()+' '+reg.dpto.toLowerCase()+' '+reg.email.toLowerCase()

          let loTiene = false;          

          let ele2 = 0;

          let cnt = 0;

          while (ele2< termBusqTmp.length ) {

            let e= termBusqTmp[ele2];

            loTiene = cadenaDestino.includes(e.toLowerCase());
            if ( loTiene ) {
              cnt ++
            }

            ele2 ++
            
          }          

          return ( cnt == termBusqTmp.length )

        } else {

          return true;

        }
        
      })
      .map( (dato,i) => ({ idx: i+1, ...dato } ))
      .slice( ( this.pagePw -1 ) * this.pagePwSize, ( this.pagePw -1 ) * this.pagePwSize + ((this.pagePwSize > this.dataB.length ? this.dataB.length : this.pagePwSize )))
      .map( (dato,i) =>{

        let datTemp: any = {
          idx : dato.idx,
          id : dato.id,
          nombre : dato.nombre,
          cargo : dato.cargo,
          gcia : dato.gcia,
          dpto : dato.dpto,
          email : dato.email
        };

        if ( this.swVinculada && filGrup ) {          

          const gus:[] = dato.grupos.filter( g =>{ 
            return  (valGrup.indexOf( String(g.name).toUpperCase() ) >= 0) 
          } )
  
          if ( gus.length>0 ) datTemp.grupos = gus; else datTemp.grupos = [];

        } else {
          datTemp.grupos = dato.grupos;
        }

        if ( this.swVinculada && (filPish || filStPi || filDPis )) {

          const pis:[] = dato.pishing.filter( r =>{ 

            const creada = new Date( r.recibido );
            
            return ( 
              (( filPish ) ? (valPish.indexOf( String(r.pishing).toUpperCase() ) >= 0 ) : true) &&  
              (( filStPi ) ? (valStPi.indexOf( String(r.status).toUpperCase() ) >= 0) : true ) &&
              (( filDPis ) ? moment(creada).isAfter(valDesde) : true ) &&
              (( filDPis ) ? moment(creada).isBefore(valHasta) : true )      )
  
          })

          if ( pis.length>0 ) datTemp.pishing = pis; else datTemp.pishing = [];
          

        } else {
          datTemp.pishing = dato.pishing;
        }

        if ( this.swVinculada && (filTrai || filStTr || filDTra || filModu || filStMo )) {

          const trai:[] = dato.training.filter( r =>{ 

            const inicio = r.inicio ;          

            return ( 
                      (( filTrai ) ? ( valTrai.indexOf( String(r.nombre_camp).toUpperCase() )>=0) : true) &&  
                      (( filStTr ) ? ( valStTr.indexOf( String(r.statust).toUpperCase() )>=0) : true ) &&
                      (( filDTra ) ? moment(inicio).isAfter(valDesde) : true ) &&
                      (( filDTra ) ? moment(inicio).isBefore(valHasta) : true ) &&     
                      (( filModu ) ? (valModu.indexOf( String(r.modulo).toUpperCase() )>=0) : true ) &&     
                      (( filStMo ) ? (valStMo.indexOf( String(r.status).toUpperCase() )>=0) : true ) 
                    )
  
          })

          if ( trai.length>0 ) datTemp.training = trai; else datTemp.training = [];

        } else {
          datTemp.training = dato.training;
        }

        return datTemp

      });

    
  }

  search( term:string ): any[] {

    const text: string ='';   


    if ( term.length >0 ) {      

      this.dataB = [];

      this.seleccionarPorTerminos( term ).then( sele =>{

        this.dataB = sele;

        if ( this.dataB.length >0 ) {
          Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            width: '38%',
            icon: 'success',
            timerProgressBar: false,
            timer: 2000,
            title: 'Se han encontrado : '+this.dataB.length+' registros'
          })
        }

        this.encontrados = this.dataB.length;

        this.refreshPage();

      })

    } else {

      this.dataB = this.dataF;
      this.encontrados = this.dataB.length;
      this.refreshPage();

    }

    if ( this.dataB.length > 0) {
      
      this.encontrados = this.dataB.length;

      this.pagePw = 1;
      
    } else {

      this.pagePw = 1;
      this.dataB = this.dataF;
      this.refreshPage();

    }
    
    this.collectionSize = this.dataB.length;

    this.refreshPage();
    

    return [];
  }

  async seleccionarPorTerminos( term ): Promise<any[]> {

    return new Promise( resolve => { 
      
      const text = term.toLowerCase();

      let termBusqTmp: any[] = text.split(/\s{1,10}/);  

      let sele : any[] = [];

      let ele = 0

      while (ele < this.dataF.length ) {

        let reg = this.dataF[ele]
        const cadenaDestino = reg.nombre.toLowerCase()+' '+reg.cargo.toLowerCase()+' '+reg.gcia.toLowerCase()+' '+reg.dpto.toLowerCase()+' '+reg.email.toLowerCase()

        let loTiene = false;

        let ele2 = 0;

        let cnt = 0;

        while (ele2< termBusqTmp.length ) {

          let e= termBusqTmp[ele2];

          loTiene = cadenaDestino.includes(e.toLowerCase());
          if ( loTiene ) {
            cnt ++
          }

          ele2 ++
          
        }

        if ( cnt == termBusqTmp.length ) {
          sele.push( reg )
        }

        ele ++        
      }

      resolve( sele );

    })
  
  }

  async tieneTerminos( term,reg:any ): Promise<boolean> {

    return new Promise( resolve => { 
      
      const text = term.toLowerCase();

      let termBusqTmp: any[] = text.split(/\s{1,10}/);  

      const cadenaDestino = reg.nombre.toLowerCase()+' '+reg.cargo.toLowerCase()+' '+reg.gcia.toLowerCase()+' '+reg.dpto.toLowerCase()+' '+reg.email.toLowerCase()

      let loTiene = false;

      let ele=0;

      while (ele < termBusqTmp.length ) {

        const e = termBusqTmp[ele];
        
        loTiene = cadenaDestino.includes(e.toLowerCase());

        if ( loTiene ) {
            resolve( true )
        } 
        
        ele ++        
      }

      resolve( false );

    })
  }

  clean( term:string ) {

    this.exportKnowbe4.controls['buscar'].setValue('');

    this.dataB = this.dataF;
    
    this.encontrados = this.dataB.length;

    this.pagePw = 1;

    this.collectionSize = this.dataB.length;

    this.refreshPage();
  }

  Vinculada() {
    this.swVinculada = ! this.swVinculada;
    this.refreshPage()
  }

  getDesGcia() {
    const g:any[] = this.exportKnowbe4.controls['swGerencias'].value;
    let retorno = ''

    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.gerenciasOpc.filter( elem => {return elem.id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.gerenciasOpc.filter( e => {return e.id == 0 })[0].name.toUpperCase()
    }
    return retorno;
  }

  getDesDpto() {
    
    const g:any[] = this.exportKnowbe4.controls['swDepartamentos'].value;    

    let retorno = ''
    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.departamentosOpc.filter( elem => {return elem.id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.departamentosOpc.filter( e => {return e.id == 0 })[0].name.toUpperCase()
    }
    return retorno;
  }

  getDesCargo() {
    const g:any[] = this.exportKnowbe4.controls['swCargos'].value;    

    let retorno = ''
    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.cargosOpc.filter( elem => {return elem.id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.cargosOpc.filter( e => {return e.id == 0 })[0].name.toUpperCase()
    }
    return retorno;    
  }

  getDesGrupo() {    
    
    const g:any[] = this.exportKnowbe4.controls['swGrupos'].value;    

    let retorno = ''
    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.gruposOpc.filter( elem => {return elem.id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.gruposOpc.filter( e => {return e.id == 0 })[0].name.toUpperCase()
    }
    return retorno; 
  }

  getDesPishing() {
    
    const g:any[] = this.exportKnowbe4.controls['swPishing'].value;    

    let retorno = ''
    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.pishingOpc.filter( elem => {return elem.campaign_id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.pishingOpc.filter( e => {return e.campaign_id == -1 })[0].name.toUpperCase()
    }
    return retorno;
  }

  getDesTraining() {

    const g:any[] = this.exportKnowbe4.controls['swTraining'].value;    

    let retorno = ''
    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.trainingOpc.filter( elem => {return elem.campaign_id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.trainingOpc.filter( e => {return e.campaign_id == -1 })[0].name.toUpperCase()
    }
    return retorno;
  }

  getEnrollmentDate( id ) {    
    const trg = this.trainingUsers.filter( e =>{ return e.enrollment_id == id })
    return trg[0].enrollment_date;
  }


  getStatusTraining( nombre:string ) {

    const g:any[] = this.exportKnowbe4.controls['swStatus_cur'].value;    

    let retorno = ''
    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.status_curOpc.filter( elem => {return elem.id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.status_curOpc.filter( e => {return e.id == "TODOS" })[0].name.toUpperCase()
    }
    return retorno;
        
  }

  getStatusTrainingRef( nombre:string ) {    

    const trg = this.training.filter( e =>{ return e.name.toUpperCase() == nombre.toUpperCase() })    
    
    return trg[0].status.toUpperCase();
        
  }

  getDesModulo() {
      
    const g:any[] = this.exportKnowbe4.controls['swPurchases'].value;    

    let retorno = ''
    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.purchasesOpc.filter( elem => {return elem.store_purchase_id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.purchasesOpc.filter( e => {return e.store_purchase_id == -1 })[0].name.toUpperCase()
    }
    return retorno;

  }

  getDesStaModulo() {
    
    const g:any[] = this.exportKnowbe4.controls['swSta_purchases'].value;    

    let retorno = ''
    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.sta_purchasesOpc.filter( elem => {return elem.id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.sta_purchasesOpc.filter( e => {return e.id == "TODOS" })[0].name.toUpperCase()
    }
    return retorno;
  }

  getDesStaPishin() {
    
    const g:any[] = this.exportKnowbe4.controls['swStatus_pis'].value;    

    let retorno = ''
    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.status_pisOpc.filter( elem => {return elem.id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.status_pisOpc.filter( e => {return e.id == "TODOS" })[0].name.toUpperCase()
    }
    return retorno;  

  }

  getDesStaTrain() {     

    const g:any[] = this.exportKnowbe4.controls['swStatus_cur'].value;    

    let retorno = ''
    if ( g.length > 0) {
      g.forEach( (e,i) =>{
        retorno = retorno + ((i==0) ? '':'|') + this.status_curOpc.filter( elem => {return elem.id == e })[0].name.toUpperCase()
      })
    } else {
      retorno = this.status_curOpc.filter( e => {return e.id == "TODOS" })[0].name.toUpperCase()
    }
    return retorno;  
  }

  gravarCache() {

    this.swProcesando = true;    

    this.knowbe4Service.gravarCache()

    this.swProcesando = false;

  }
  
  
}
